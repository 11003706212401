export const GalleryContentSections =  {
    description: "Descrizione",
    images: "Immagini",
    more_info: "Ulteriori informazioni"
}

export class GalleryClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.content = data.content;
        this.last_update = data.last_update;
    }
}

