import { OfficeClass } from 'pa_kit/apps/offices/models/office';
import { CategoryClass } from '../../categories/models/category';
import { TagClass } from 'pa_kit/apps/tags/models/tag';

export const NewsContentSections =  {
    description: "Descrizione",
    attachments: "Documenti",
    office: "Ufficio",
    more_info: "Ulteriori informazioni"
}

export class NewsClass {    
  
    constructor(data){
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.image = data.image;
        this.meta_description = data.meta_description;
        this.image_description = data.image_description;
        this.content = data.content;
        this.category = new CategoryClass(data.category_data);
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
        this.date_news = data.date_news;
        this.date_published = data.date_published;
        this.is_sticky = data.is_sticky;
        this.state = data.state;
        this.user = data.user;
        this.last_update = data.last_update;
    }
}

