// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import ApiService from "./api"; 

const LocalSettingsContext = createContext({});

export default function LocalSettingsProvider({ children }) {    

    const [ localSettings, setLocaleSettings ] = useState();
    const [ isLocalSettingsLoading, setIsLocalSettingsLoading ] = useState(true);

    const fetchLocalSettings = async() => {
        const settings = await ApiService.getLocalSettings();
        setLocaleSettings(settings); 
        setIsLocalSettingsLoading(false);
    }

    useEffect(() => {        
        fetchLocalSettings();
    }, []);

    const settings = {        
        localSettings,
        isLocalSettingsLoading,
    }

    return (
        <LocalSettingsContext.Provider value={settings}>
            {children}
        </LocalSettingsContext.Provider>
    );
}

export { LocalSettingsContext };