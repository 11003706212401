import { Service } from "./service";

export class ServiceCategory {

    constructor(data){

        this.id = data.id;
        this.slug = data.slug;
        this.name = data.name ;
        this.description = data.description;

        this.services = data.services ? data.services.map((serviceData) => new Service(serviceData)) : [];
    }
}
