/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Button, Container, Row, Col, Breadcrumb, BreadcrumbItem, Icon, Dropdown, DropdownToggle, DropdownMenu, LinkList, LinkListItem } from 'design-react-kit';
import React, { useEffect, useState, useContext, createContext } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from "../../../services/api";
import { PageClass } from '../models/page';
import { ActaAuthContext } from "pa_kit/auth";
import { Helmet } from "react-helmet-async";
import Page404 from '../page404';
import WidgetPageRating from 'pa_kit/apps/page_evaluation/components/widget_page_rating';
import WidgetContacts from 'pa_kit/apps/contacts/components/widget_contacts';
import env from '../../../env';
import ShareButtons from 'apps/utils/share_buttons';

export const PageContext = createContext();

const Page = () => {

    const auth = useContext(ActaAuthContext);

    const organization = auth.organization;
    const user = auth.user;

    const isActagovWebsiteEditor = auth.user && auth.user.isActagovWebsiteEditor();
    const isActagovContentEditor = auth.user && auth.user.isActagovContentEditor();

    const actagov_config = auth.organization.active_licenses?.services?.actagov;

    const [ addContentOpen, addContentToggle ] = useState(false);

    let { slug } = useParams();

    if(!slug){
        slug = 'homepage';
    }
    
    const [ page, setPage ] = useState();
    const [ breadcrumb, setBreadcrumb ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);
    const [ pageNotFound, setPageNotFound ] = useState(false);

    const [ newPage, setNewPage ] = useState({});

    const [ json, setJson ] = useState();
    const [ css, setCss ] = useState();    
  
    const generateJson = () => {
        if(!page) return;
        setJson(JSON.stringify(page.content.toJson(), null, 4) ?? "Error");
        // console.log(page.content);
    }
  
    const generateCss = () => {
        if(!page) return;        
        setCss(page.content.toCss() ?? "Error");        
    }

    const [ pageInfo, setPageInfo ] = useState({
        editMode: false,
        previewMode: false,
        page_slug: slug,
        page_version: 0
    });

    const fetchPage = async() => {
        try {
            const pageContent = await ApiService.getPage(slug);
            setPage(new PageClass(pageContent));
            setNewPage({
                content: pageContent.content
            });
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setPageNotFound(true);
        }
    }

    useEffect(() => {
        fetchPage();
    }, []);

    useEffect(() => { 
        generateJson();
        generateCss(); 
    }, [page]);

    const fetchBreadcrumb = async() => {
        if(page) {
            let breadcrumb = await page.getBreadcrumb();  
            setBreadcrumb(breadcrumb);
        }
    }
    
    useEffect(() => {
        fetchBreadcrumb();
    }, [page]);

    const handleSubmit = async() => {        

        newPage.content = page.content.toJson();
        
        // console.log(newPage);

        try {
            await ApiService.editPage(page.slug, newPage);
            window.location.reload();
        } catch(err) {
            console.log(err);
        }
    }
    
    if(pageNotFound) return <Page404 />;
    if(isLoading) return <Container style={{ padding: "50px", height: "100vh" }}></Container>;    
    return (
        <>        
        <Helmet>
            <title>{page.title === 'Homepage' ? '' : page.title + ' |'} {organization.name}</title>
            <meta name="description" content={page.meta_description} />
            {page.meta_img !== '' && <meta property="og:image" content={env.BACKEND_BASE_URL + page.meta_img} />}
        </Helmet>
        {(isActagovWebsiteEditor || isActagovContentEditor) && <>
            <Row className="admin-bar text-end g-0">
                <Col>
                    <Dropdown className='me-3 admin-bar-dropdown' isOpen={addContentOpen} onMouseEnter={() => addContentToggle(!addContentOpen)} onMouseLeave={() => addContentToggle(!addContentOpen)}>
                        <DropdownToggle caret className="p-2 admin-bar-btn me-4" tag="span">
                            <Icon icon="it-plus" size="sm" /> Inserisci
                        </DropdownToggle>
                        <DropdownMenu>
                        <LinkList>
                            <LinkListItem href="/admin/create-news"><Icon icon="it-horn" size="sm" color="white" /> Articolo</LinkListItem>
                            <LinkListItem href="/admin/create-event"><Icon icon="it-calendar" size="sm" color="white" /> Evento</LinkListItem>
                            <LinkListItem href="/admin/create-document"><Icon icon="it-files" size="sm" color="white" /> Documento</LinkListItem>
                        </LinkList>
                        </DropdownMenu>
                    </Dropdown>
                    {isActagovWebsiteEditor && 
                        <>
                            {pageInfo.editMode === false && <Button className="p-2 admin-bar-btn me-4" onClick={() => setPageInfo({...pageInfo, editMode: true, previewMode: false})}> <Icon icon="it-pencil" size="sm" /> Modifica Pagina</Button>}
                            {pageInfo.editMode === true && <><Button className="p-2 admin-bar-btn" onClick={handleSubmit}><Icon icon="it-software" size="sm" /> Salva Pagina</Button> <Button className="p-2 admin-bar-btn me-4" onClick={() => setPageInfo({...pageInfo, editMode: false, previewMode: true})}> <Icon icon="it-password-visible" size="sm" /> Anteprima</Button></>}
                        </>
                    }
                </Col>
            </Row>
            </>
        }
        <div className={'page'+page.id+'wrapper'}>
            {pageInfo.editMode === false && pageInfo.previewMode === false &&
                <style>
                    {css}
                </style>
            }
            
            {slug !== 'homepage'
                ? <>
                <Container className={"pt-5 px-3 page-breadcrumb page-breadcrumb"+page.id}>                    
                    <Row>
                        <Col>
                            <Breadcrumb>
                            {breadcrumb &&
                                <BreadcrumbItem>
                                    <a href='/'>Home</a>
                                    <span className='separator'>/</span>
                                </BreadcrumbItem>
                            }
                                {breadcrumb && breadcrumb.map((crumb, i) =>
                                    <React.Fragment key={"key_breadCrumb_" + i}>
                                    {(i < breadcrumb.length - 1)
                                        ?   <BreadcrumbItem>
                                                <a href={'/' +  crumb[1] + '/'}>{crumb[0]}</a>
                                                <span className='separator'>/</span>
                                            </BreadcrumbItem>
                                        :   <BreadcrumbItem>
                                                {crumb[0]}                                            
                                            </BreadcrumbItem>                                    
                                    }
                                    </React.Fragment> 
                                )}
                             </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
                <Container className={'page-title page-title-page'+page.id}>
                    <Row>
                        <Col lg="7">
                            <h1>{page.title}</h1>
                        </Col>
                        <Col lg={{size: 4, offset: 1}}>
                            <ShareButtons url={actagov_config?.frontend_url + page.slug + '/'} title={page.title} />
                        </Col>
                    </Row>                    
                </Container>
                </>
                : null}
            
            <PageContext.Provider value={pageInfo}>
                {page.content.render()}
            </PageContext.Provider>

            <Container fluid className={'rating-wrapper page-rating'+page.id}>
                <WidgetPageRating page_title={page.title} />
            </Container>

            <Container fluid className={'contacts-wrapper page-contacts'+page.id}>
                <WidgetContacts />
            </Container>

            {/* {user && user.isSuperuser() && pageInfo.editMode && <>                
                <Container style={{ padding: "10px 0px" }}>
                    <Button onClick={(_) => generateJson()}>GENERATE JSON</Button>
                    <TextArea rows={30} value={json} style={{ fontSize: 14 }} />
                </Container>
                <Container style={{ padding: "10px 0px" }}>
                    <Button onClick={(_) => generateCss()}>GENERATE CSS</Button>
                    <TextArea rows={30} value={css} style={{ fontSize: 14 }} />
                </Container>               
                </>
            }             */}
        </div>        
        </>        
    );
  }
  
    
  export default Page;