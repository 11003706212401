// @ts-nocheck
import { Container, LinkList, LinkListItem, Card, CardBody, CardTitle, CardText, CardReadMore, Row, Col } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import authApiService from "pa_kit/services/api";
import { MenuClass } from '../models/menu';

const WidgetMenu = (props) => {

    const [ widget ] = useState(props.widget);     
    const [ pageInfo ] = useState(props.pageInfo);
    const managementTaxonomy = [ 'Organi di governo', 'Documenti e dati', 'Aree amministrative', 'Uffici', 'Politici', 'Personale amministrativo', 'Enti e fondazioni' ];

    const pageSlug = pageInfo.page_slug;

    let dataElement;
    let taxonomy;

    switch(pageSlug) {

        case 'amministrazione':
            dataElement = 'management-category-link';
            taxonomy = managementTaxonomy;
            break;

        default:
            dataElement = 'custom-category-link';
            taxonomy = '';
    }

    const idMenu = widget.data.id_menu;

    const [ menu, setMenu ] = useState({});

    const fetchMenu = async() => {
        const menuData = await authApiService.getMenu(idMenu);
        setMenu(new MenuClass(menuData));
    }

    useEffect(() => {
        fetchMenu();
    }, []);

    let menuListStyle;
    switch(widget.data.list_style) {

        case 'cards':
            menuListStyle = <>
                <Container fluid className={widget.data.class + '-menu page-menu-content'}>
                    <h2 className="pt-5">{widget.data.menu_title || menu.name}</h2>
                    <Row>
                    {menu && menu.content?.items.map((item) =>                
                        <Col xs='12' lg='4' className="mt-4" key={"key_menuItem_" + item.name}>                    
                            <Card className="menu-card border border-light shadow-sm">
                                <CardBody>
                                    <CardTitle tag='h3' className='big-heading'>
                                        <a href={item.url} className="text-decoration-none me-2" title={item.name} data-element={taxonomy !== '' ? (taxonomy.includes(item.name) ? dataElement : 'custom-category-link') : dataElement} target={item.blank ? '_blank' : '_self'} rel={item.blank ? 'noreferrer' : ''}>{item.name}</a>
                                    </CardTitle>
                                    <CardText>
                                        {item.description}
                                    </CardText>
                                    <CardReadMore href={item.url} target={item.blank ? '_blank' : '_self'} rel={item.blank ? 'noreferrer' : ''} text='Vai alla pagina' iconName='it-arrow-right' />
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                    </Row>
                </Container>
            </>;
            break;

        default:
            menuListStyle = <>
                <Container fluid className={widget.data.class + '-menu page-menu-content ms-lg-5 ps-lg-5'}>            
                    <p className="menu-name mb-2">{widget.data.menu_title || menu.name}</p>
                    <LinkList>
                        {menu && menu.content?.items.map((item) => 
                            <LinkListItem key={"key_menuItem_" + item.name} href={item.url} data-element={dataElement} className="ps-0 menu-item-link" target={item.blank ? '_blank' : '_self'} rel={item.blank ? 'noreferrer' : ''}>{item.name}</LinkListItem>
                        )}
                    </LinkList>
                </Container>
            </>;
    }

    return <>{menuListStyle}</>;
}

export default WidgetMenu;