// @ts-nocheck
import React from "react";
import { createContext } from "react";
import Widget from "../components/widget";

export const WidgetContext = createContext();

export class WidgetClass {
 
    constructor(data){
        this.data     = data;
        this.children = data.children?.map((childData) => new WidgetClass(childData)) || [];
    }
  
    hasChildren() {
        return this.children.length > 0;
    }

    hasCss() {
        return this.data.css && this.data.css !== '';
    }
  
    addChild(widgetData) {  

        let newWidget;
        
        if(widgetData.content_type === 'widget_section') {
            switch(widgetData.layout_type) {
                case 'full_width':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        direction: "row",
                        children: []
                    });
                    break;

                case 'boxed_width':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "boxed",
                        direction: "row",
                        children: []
                    });
                    break;

                case 'seven_five':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "boxed",
                        direction: "row",
                        children: [
                            {
                                name: this.data.class + "_" + this.children.length + "_col1",
                                class: this.data.class + "_" + this.children.length + "_col1",
                                col_size: 7,
                                children: []
                            },
                            {
                                name: this.data.class + "_" + this.children.length + "_col2",
                                class: this.data.class + "_" + this.children.length + "_col2",
                                col_size: 5,
                                children: []
                            }
                        ]
                    });
                    break;

                default:
            }

        } else if(widgetData.content_type === 'widget_item') {
            switch(widgetData.widget_item) {
                case 'structure':
                    if(widgetData.widget_list_style === 'single_structure'){
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "structure",
                            id_structure: widgetData.widget_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "structure",
                            label: widgetData.widget_menu_title                            
                        });
                    }                    
                    break;

                case 'sector':
                    if(widgetData.widget_list_style === 'single_sector'){
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "sector",
                            id_sector: widgetData.widget_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "sector",
                            label: widgetData.widget_menu_title
                        });
                    }
                    break;

                case 'office':
                    if(widgetData.widget_list_style === 'single_office'){
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "office",
                            id_office: widgetData.widget_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "office",
                            label: widgetData.widget_menu_title
                        });
                    }
                    break;

                case 'staff':
                    if(widgetData.widget_list_style === 'single_staff'){
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "staff",
                            id_staff: widgetData.widget_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "staff",
                            staff_type: widgetData.widget_list_style,
                            label: widgetData.widget_menu_title
                        });
                    }
                    break;

                case 'text':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "text",
                        id_text: widgetData.widget_id
                    });
                    break;

                case 'gallery':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "gallery",
                        id_gallery: widgetData.widget_id
                    });
                    break;

                case 'place':
                    if(widgetData.widget_list_style === 'single_place'){
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "place",
                            place_slug: widgetData.widget_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "place",
                            category: widgetData.widget_category,
                            id_tag: widgetData.widget_id_tag,
                            label: widgetData.widget_menu_title
                        });
                    }
                    
                    break;

                case 'custom_widget':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "custom_widget",                        
                        id_custom_widget: widgetData.widget_id
                    });
                    break;

                case 'menu':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "menu",                        
                        id_menu: widgetData.widget_id,
                        list_style: widgetData.widget_list_style,
                        menu_title: widgetData.widget_menu_title
                    });
                    break;

                case 'tags':
                    if(widgetData.widget_list_style === 'tags_home') {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "tags_home"
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "tags",                        
                            page_slug: widgetData.widget_page_slug,
                            list_style: widgetData.widget_list_style
                        });
                    }                    
                    break;

                case 'search':
                    if(widgetData.widget_search_type === 'search_standard') {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "search",
                            label: widgetData.widget_search_label,                        
                            search_in: widgetData.widget_search_in || 'main_sections',
                            search_id: widgetData.widget_search_id
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "search_home",
                            label: "Cerca nel sito",                     
                            search_in: "website"
                        });
                    }                    
                    break;

                case 'calendar':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "calendar",
                        label: widgetData.widget_calendar_label                      
                    });
                    break;

                case 'news':
                    if(widgetData.widget_list_style === 'news_home') {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "news_home",
                            category: "all"                     
                        });
                    } else {
                        newWidget = new WidgetClass({
                            name: this.data.class + "_" + this.children.length,
                            class: this.data.class + "_" + this.children.length,
                            layout: "fluid",
                            type: "news",
                            category: widgetData.widget_category,
                            id_tag: widgetData.widget_id_tag,
                            id_office: widgetData.widget_id_office,
                            show_number: widgetData.widget_show_number || 4,
                            label: widgetData.widget_menu_title                   
                        });
                    }                    
                    break;

                case 'event':                    
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "event",
                        category: widgetData.widget_category,
                        id_tag: widgetData.widget_id_tag,
                        id_office: widgetData.widget_id_office,
                        show_number: widgetData.widget_show_number || 4,
                        label: widgetData.widget_menu_title                   
                    });                   
                    break;

                case 'stats':
                    newWidget = new WidgetClass({
                        name: this.data.class + "_" + this.children.length,
                        class: this.data.class + "_" + this.children.length,
                        layout: "fluid",
                        type: "stats"                  
                    });                   
                    break;
                
                default:
            } 
        }
        
        this.children.push(newWidget);
    }

    editChild(widgetCss) {
        this.data.css = {};
        widgetCss.forEach(css =>
            this.data.css[css[0].css_key] = css[0].css_value
        );
    }

    deleteChild() {
        this.data.type = 'delete_item';
    }
  
    toJson() {
        return {
            ...this.data,
            children: this.children.map((child) => child.toJson()).filter((child) => child.type !== 'delete_item')
        }        
    }  
  
    //toCss(indentation = 0) {
    toCss() {

        if(!this.hasChildren() && !this.hasCss()) return "";
  
        //let indentationString = "";
        //for(let i=0; i<indentation; i++) indentationString += "\t";
  
        let childrenCss = "";
        if(this.hasChildren()) {
            for(let child of this.children){
                //childrenCss += child.toCss(indentation + (this.hasCss() ? 0 : 0));
                childrenCss += child.toCss();
            }
        }
  
        let css = "";
        if(this.hasCss()) {
            Object.keys(this.data.css).forEach(function(key) {
                //css += indentationString + "\t" +  key + ": " + this.data.css[key] + ";\n";
                css += "\t" +  key + ": " + this.data.css[key] + ";\n";
            }.bind(this));
  
            return ''
                //+ "\n" + indentationString + (indentation ? "& #" : "#") + this.data.id + " {\n"
                + "\n."  + this.data.class + " {\n"
                + css
                + "}\n"
                + childrenCss;
                  
        } else {
            return childrenCss;
        }      
    }

    toWidgetCss() {
        let childrenCss = "";  
        let css = "";
        if(this.hasCss()) {
            Object.keys(this.data.css).forEach(function(key) {
                //css += indentationString + "\t" +  key + ": " + this.data.css[key] + ";\n";
                css += "\t" +  key + ": " + this.data.css[key] + ";\n";
            }.bind(this));
  
            return ''
                //+ "\n" + indentationString + (indentation ? "& #" : "#") + this.data.id + " {\n"
                + "\n."  + this.data.class + " {\n"
                + css
                + "}\n"
                + childrenCss;
                  
        } else {
            return childrenCss;
        }
    }
  
    render() {    
        return (
            <WidgetContext.Provider value={this}>
               <Widget />
            </WidgetContext.Provider>
        );
    }
}