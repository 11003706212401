/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Icon, AvatarWrapper, AvatarExtraText, AvatarIcon, Card, CardBody, CardText, CardTitle, CardReadMore, Spinner } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import authApiService from "pa_kit/services/api";
import { OfficeClass, OfficeContentSections } from '../models/office';
import { ActaAuthContext } from "pa_kit/auth";
import env from '../../../../env';


const WidgetOffice = (props) => {
    const auth = useContext(ActaAuthContext);
    const auth_config = auth.organization.active_licenses?.services?.auth;

    const [ widget ] = useState(props.widget);

    const [ offices, setOffices ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let id_office;

    if(widget.data.id_office) {
        id_office = widget.data.id_office;
    }

    const fetchOffices = async() => {
        if(id_office !== undefined) {
            const officesData = await authApiService.getOffice(id_office);
            setOffices(new OfficeClass(officesData));
        } else {
            const officesData = await authApiService.getOffices();
            setOffices(officesData);
        }

        setIsLoading(false);
    }    

    useEffect(() => {
        fetchOffices();
    }, []);

    if(id_office === undefined) return <>
        <h2 className="office-page-title mt-5">{widget.data.label ? widget.data.label : 'Elenco Uffici'}</h2>
        <Container fluid className={widget.data.class + '-office page-office-content'}>
            <Row>
            {offices && offices.map((office) => 
                <Col xs='12' lg='4' className={"mt-4 office-" + office.id} key={"key_officeItem_" + office.id}>                    
                    <Card className="office-card border border-light shadow-sm">
                        <CardBody>
                            <CardTitle tag='h3' className='big-heading'>
                                <a href={'/' + office.slug + '/'} className="text-decoration-none me-2" title={office.name}>{office.name}</a>
                            </CardTitle>
                            <CardText>
                                {office.content.excerpt ? parse(office.content.excerpt.description) : ''}
                            </CardText>
                            <CardReadMore href={'/' + office.slug + '/'} text='Vai alla pagina' iconName='it-arrow-right' />
                        </CardBody>
                    </Card>
                </Col>
            )}
            </Row>
        </Container>
    </>

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;
    return <>
        <Container className="mb-5">
            <Row className="office-content-wrapper">
                <Col lg={4} className="office-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(OfficeContentSections).map((key, index) => ((offices?.content[key] && (Array.isArray(offices?.content[key]) ? (offices?.content[key].length > 0) : (offices?.content[key].description !== '' && offices?.content[key].description !== null))) || (key === 'staff' && offices[key].length > 0) || (key === 'sectors' && offices[key].length > 0) || (key === 'contacts' && (offices?.content[key].description !== '' || (offices?.reception_hours?.length > 0 && offices.reception_hours?.some(reception_hour => 
                                            reception_hour.reception_hours.morning.from !== "Chiuso" ||
                                            reception_hour.reception_hours.morning.to !== "Chiuso" ||
                                            reception_hour.reception_hours.afternoon.from !== "Chiuso" ||
                                            reception_hour.reception_hours.afternoon.to !== "Chiuso"
                                        ))))) &&
                                    <NavItem key={"key_officelink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(OfficeContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="office-section-content">
                    {Object.keys(OfficeContentSections).map((key, index) => (offices?.content[key] || key === 'staff' || key === 'sectors') &&
                        <div key={"key_officeSection_" + index} className="mb-4">                            
                            {key !== 'staff' && key !== 'sectors' && key !== 'contacts' && offices?.content[key].description &&  <> 
                                <h4 className="office-content-title" id={key}>{ Object.values(OfficeContentSections)[index] }</h4>                           
                                <div className="office-content-description">
                                    { parse(offices.content[key].description) }
                                </div>
                            </>}

                            {key === 'sectors' && offices[key].length > 0 && <>
                                <h4 className="office-content-title" id={key}>{ Object.values(OfficeContentSections)[index] }</h4>                       
                                <Row className="mb-2">
                                    {offices && offices.sectors?.map((sector) =>
                                    <Col lg="6" key={"key_sectorItem_" + sector.id}>
                                        <Card className="sector-card-box">
                                            <CardBody className="sector-card">
                                                <Row>
                                                    <Col lg="8" xs="8">
                                                        <CardTitle tag='h5' className="mb-1">
                                                            <a href={'/' + sector.slug + '/'} title={sector.name}>{sector.name}</a>
                                                        </CardTitle>
                                                        <CardText>
                                                            Settore
                                                        </CardText>
                                                    </Col>
                                                    <Col lg={{size: 3, offset: 1}} xs="4">
                                                        <Icon icon="it-pa" size="lg" />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    )}
                                </Row>
                                </>    
                            }

                            {key === 'contacts' && (offices.content[key].description !== '' || (offices.reception_hours?.length > 0 && (offices.reception_hours?.some(reception_hour => 
                                reception_hour.reception_hours.morning.from !== "Chiuso" ||
                                reception_hour.reception_hours.morning.to !== "Chiuso" ||
                                reception_hour.reception_hours.afternoon.from !== "Chiuso" ||
                                reception_hour.reception_hours.afternoon.to !== "Chiuso"
                            )))) && <>
                                <h4 className="office-content-title" id={key}>{ Object.values(OfficeContentSections)[index] }</h4>                           
                                <div className="office-content-description">
                                    { parse(offices.content[key].description) }
                                </div>
                                {offices.reception_hours?.length > 0 && (
                                    <div className="office-reception-hours-info mt-2">
                                        {offices.reception_hours?.some(reception_hour => 
                                            reception_hour.reception_hours.morning.from !== "Chiuso" ||
                                            reception_hour.reception_hours.morning.to !== "Chiuso" ||
                                            reception_hour.reception_hours.afternoon.from !== "Chiuso" ||
                                            reception_hour.reception_hours.afternoon.to !== "Chiuso"
                                        ) ? (<>
                                            <p><strong>Orari di ricevimento</strong></p>
                                            <div className="table-responsive">
                                                <table className="table table-striped" style={{fontSize: "15px"}}>
                                                    <thead>
                                                        <tr>
                                                            <th>Giorno</th>
                                                            <th>Mattina</th>
                                                            <th>Pomeriggio</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {offices.reception_hours?.map((reception_hour, i) => <React.Fragment key={'receptionHoursKey_' + i}>
                                                        <tr>
                                                            <td>
                                                                <strong>{reception_hour.day}</strong>
                                                            </td>
                                                            <td>
                                                                {reception_hour.reception_hours.morning.from === 'Chiuso' ? '-'
                                                                : reception_hour.reception_hours.morning.from + ' - ' + reception_hour.reception_hours.morning.to}
                                                            </td>
                                                            <td>
                                                                {reception_hour.reception_hours.afternoon.from === 'Chiuso' ? '-'
                                                                : reception_hour.reception_hours.afternoon.from + ' - ' + reception_hour.reception_hours.afternoon.to}
                                                            </td>
                                                        </tr>
                                                        </React.Fragment>                            
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>
                                            </>
                                        ) : ""}
                                    </div>
                                )}
                                </>                             
                            }

                            {key === 'staff' && offices[key].length > 0 && <>
                                <h4 className="office-content-title" id={key}>{ Object.values(OfficeContentSections)[index] }</h4>     
                                <Row> 
                                    {offices?.staff.map((staff) =>
                                    <Col lg="6" key={"key_staffSection_" + staff.id} className="staff-card-col">
                                        <AvatarWrapper className="staff-card" extra='text'>
                                            <AvatarIcon size='xl'>
                                                <img src={staff.image ? auth_config?.backend_url + staff.image : auth_config?.backend_url + 'media/organization/default_avatar.jpg'} alt={staff.first_name + ' ' + staff.last_name} />
                                            </AvatarIcon>
                                            <AvatarExtraText>
                                                <h4> <a href={'/' + staff.slug + '/'}>{staff.first_name + ' ' + staff.last_name}</a> </h4>
                                                <p>{staff.role}</p>
                                            </AvatarExtraText>
                                        </AvatarWrapper> 
                                    </Col>
                                    )} 
                                </Row>
                                </>                             
                            }

                            {key === 'documents' && offices.content[key].length > 0 && <>
                                <h4 className="office-content-title" id={key}>{ Object.values(OfficeContentSections)[index] }</h4>    
                                {offices.content[key].map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                <div className="mt-3">{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={auth_config?.backend_url + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                                )}
                                </>
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(offices.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>
}

export default WidgetOffice;