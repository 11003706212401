/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Card, CardBody, CardCategory, CardTitle, Container, Icon, Row, Col, Spinner } from 'design-react-kit';
import React, { useState, useEffect, useContext } from 'react';
import ApiService from '../../../services/api';
import moment from 'moment';
import { LocalSettingsContext } from "services/local_settings";
import Pagination from 'pa_kit/pagination';
import env from '../../../env';
import parse from 'html-react-parser';

const WidgetEvent = (props) => {

    const settings = useContext(LocalSettingsContext);

    const localSettings = settings.localSettings;
        
    const eventsParentCategorySlug = localSettings.events_parent_category === null ? 'novita' : localSettings.events_parent_category_data.slug

    const [ widget ] = useState(props.widget);

    const [ eventsList, setEventsList ] = useState();
    const [ currentEvents, setCurrentEvents ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const [ paginationState, setPaginationState ] = useState({
        currentPage: 1,
        eventsPerPage: widget.data.show_number
    });

    const handlePageChange = (pageNumber) => {
        setPaginationState({ ...paginationState, currentPage: pageNumber });
    };

    let category;
    let id_tag;
    let id_office;

    let show = widget.data.show_number;

    let page = props.page || '';

    if(widget.data.category){
        if(widget.data.category === 'all') {
            category = "";
        } else{
            category = widget.data.category;
        } 
    }

    if(widget.data.id_tag){
        id_tag = widget.data.id_tag;
    }

    if(widget.data.id_office){
        id_office = widget.data.id_office;
    }

    const fetchEventsList = async() => {

        let eventsListData;

        if(!eventsList) {

            if(category !== undefined) {
                if(show > 4) {
                    eventsListData = await ApiService.getEventsList(category);
                    eventsListData = eventsListData.filter(event => event.state === 'active');
                    console.log(eventsListData);
                } else {
                    if(category === "") {
                        eventsListData = await ApiService.getLatestEvents();
                    } else {
                        eventsListData = await ApiService.getLatestEventsByCategory(category);
                    }
                    
                }                
            } else if(id_tag !== undefined) {
                eventsListData = await ApiService.getLatestEventsByTag(id_tag);
                // eventsListData = eventsListData.filter(event => event.tags.some(tag => tag.id === id_tag));
                // eventsListData = eventsListData.filter(event => event.state === 'active');
            } else if(id_office !== undefined) {
                eventsListData = await ApiService.getEventsByOffice(id_office);                
                // eventsListData = eventsListData.filter(event => (event.office?.id) === id_office);
                // eventsListData = eventsListData.filter(event => event.state === 'active');
            } 

            setEventsList(eventsListData);
            setIsLoading(false);
        } else {
            eventsListData = eventsList;            
        }               
        
        const indexOfLastElement = paginationState.currentPage * paginationState.eventsPerPage;
        const indexOfFirstElement = indexOfLastElement - paginationState.eventsPerPage;
        const currentEventsListData = eventsListData.slice(indexOfFirstElement, indexOfLastElement);        
        setCurrentEvents(currentEventsListData);
   
    }

    useEffect(() => {
        fetchEventsList();
    }, [paginationState]);

    if(isLoading) return <Container>{page !== 'tag' && <Spinner active />}</Container>
    if(eventsList && eventsList.length === 0) return <>
        {page !== 'tag' &&
            <Container>
                <p>Nessun risultato</p> 
                {widget.data.all_events_button && <center><button href="/eventi/" onClick={(e) => { e.preventDefault(); window.location.href='/eventi'; }} type="button" data-element="live-button-events" className="btn btn-primary">Tutti gli eventi</button></center>}
            </Container>
        }        
    </>

    if(show > 3) return <>
        {page === 'tag' && eventsList && eventsList.length !== 0 &&
            <Container className="mt-5 pt-5">
                <h2 className="news-by-tags-title mb-4 px-1">Eventi</h2>  
            </Container>
        }
        <h2 className="news-page-title mt-2">{widget.data.label ? widget.data.label : 'Elenco Eventi'}</h2>
        <Container fluid className={widget.data.class + '-news page-news-content'}>
            <Row id="events-elements-wrapper">
            {currentEvents && currentEvents.map((event) => {
                return (
                        <Col key={event.slug} xs='12' lg='4' className="mt-2">
                            <Card className='card-img no-after shadow-sm'>
                            {event.image && event.image !== '' && event.image.substring(event.image.lastIndexOf('/') + 1) !== 'default.jpg' && 
                                <div className='img-responsive-wrapper'>
                                    <div className='img-responsive img-responsive-panoramic'>
                                        <figure className='img-wrapper'>
                                            <img alt="Copertina Notizia" className="img-fluid" src={env.BACKEND_BASE_URL + event.image || ''} title={event.title} />
                                            <div className='card-calendar d-flex flex-column justify-content-center'>
                                                <span className='card-date'>{moment(event.content.timeline.length > 0 ? event.content.timeline[0].date  : event.date_event).format('DD')}</span>
                                                <span className='card-day'>{moment(event.content.timeline.length > 0 ? event.content.timeline[0].date  : event.date_event).format('MMMM')}</span>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            }   
                                <CardBody className="px-4">
                                    <CardCategory date={moment(event.date_event).format('DD/MM/YYYY')} iconName="it-calendar"> 
                                        {event.category.name} 
                                    </CardCategory>
                                    <CardTitle className="fw-semibold"> <a className="news-title-link" href={eventsParentCategorySlug === 'novita' ? '/novita/eventi/'+ event.category.slug + '/' + event.slug : '/eventi/'+ event.category.slug + '/' + event.slug + '/'} data-element="event-link">{event.title}</a> </CardTitle>
                                    <div className="mt-2 news-description mb-5">
                                        {parse((event.content.description.description).replace(/(<([^>]+)>)/gi, "").substring(0, 100) + '...')}
                                    </div>
                                    <a className="read-more position-relative" href={eventsParentCategorySlug === 'novita' ? '/novita/eventi/' + event.category.slug + '/' + event.slug : '/eventi/' + event.category.slug + '/' + event.slug + '/'} data-element="event-link">Leggi di più <Icon icon="it-arrow-right" /></a>
                                    <div className="mt-5">
                                    {event.tags && event.tags.map((tag) => 
                                        <div key={tag.slug} className={"chip chip-simple chip-primary me-2"}>
                                            <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                        </div>
                                    )} 
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                    
                )
            })}
            </Row>
            <Pagination
                elementsPerPage={paginationState.eventsPerPage}
                totalElements={eventsList && eventsList.length}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                type="events"
            />
            {widget.data.all_events_button && <center className="mt-3"><button href="/eventi/" onClick={(e) => { e.preventDefault(); window.location.href='/eventi'; }} type="button" data-element="live-button-events" className="btn btn-primary">Tutti gli eventi</button></center>}
        </Container>        
    </>;

    return <>
        <h2 className="news-page-title mt-2">{widget.data.label ? widget.data.label : 'Elenco Eventi'}</h2>
        <Container fluid className={widget.data.class + '-news page-news-content'}>
            <Row>
            {eventsList && eventsList.slice(0, show).map((event) => {
                return (
                        <Col key={event.slug} xs='12' lg='4' className="mt-2">
                            <Card className='card-img no-after shadow-sm'>
                            {event.image && event.image !== '' && event.image.substring(event.image.lastIndexOf('/') + 1) !== 'default.jpg' && 
                                <div className='img-responsive-wrapper'>
                                    <div className='img-responsive img-responsive-panoramic'>
                                        <figure className='img-wrapper'>
                                            <img alt="Copertina Notizia" className="img-fluid" src={env.BACKEND_BASE_URL + event.image || ''} title={event.title} />
                                            <div className='card-calendar d-flex flex-column justify-content-center'>
                                                <span className='card-date'>{moment(event.content.timeline.length > 0 ? event.content.timeline[0].date  : event.date_event).format('DD')}</span>
                                                <span className='card-day'>{moment(event.content.timeline.length > 0 ? event.content.timeline[0].date  : event.date_event).format('MMMM')}</span>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            }   
                                <CardBody className="px-4">
                                    <CardCategory date={moment(event.date_event).format('DD/MM/YYYY')} iconName="it-calendar"> 
                                        {event.category.name} 
                                    </CardCategory>
                                    <CardTitle className="fw-semibold"> <a className="news-title-link" href={eventsParentCategorySlug === 'novita' ? '/novita/eventi/'+ event.category.slug + '/' + event.slug + '/' : '/eventi/'+ event.category.slug + '/' + event.slug + '/'} data-element="event-link">{event.title}</a> </CardTitle>
                                    <div className="mt-2 news-description mb-5">
                                        {parse((event.content.description.description).substring(0, 100).replace(/(<([^>]+)>)/gi, "") + '...')}
                                    </div>
                                    <a className="read-more position-relative" href={eventsParentCategorySlug === 'novita' ? '/novita/eventi/' + event.category.slug + '/' + event.slug + '/' : '/eventi/' + event.category.slug + '/' + event.slug + '/'} data-element="event-link">Leggi di più <Icon icon="it-arrow-right" /></a>
                                    <div className="mt-5">
                                    {event.tags && event.tags.map((tag) => 
                                        <div key={tag.slug} className={"chip chip-simple chip-primary me-2"}>
                                            <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                        </div>
                                    )} 
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>                    
                )
            })}
            </Row>
            {widget.data.all_events_button && <center className="mt-3"><button href="/eventi/" onClick={(e) => { e.preventDefault(); window.location.href='/eventi'; }} type="button" data-element="live-button-events" className="btn btn-primary">Tutti gli eventi</button></center>}
        </Container>        
    </>;
}

export default WidgetEvent;