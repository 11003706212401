/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Card, CardBody, CardCategory, CardReadMore, CardTitle, Col, Container, Icon, Row } from 'design-react-kit';
import React, { useEffect, useState } from 'react';
import ApiService from "../../../services/api";
import { NewsClass } from "../models/news";
import moment from 'moment';
import env from '../../../env';
import parse from 'html-react-parser';

const WidgetNewsHome = (props) => {

    const [ widget ] = useState(props.widget);
    
    const [stickyNews, setStickyNews] = useState();
    const [latestNews, setLatestNews] = useState();

    const [isStickyNewsLoading, setIsStickyNewsLoading] = useState(true);
    const [isLatestNewsLoading, setIsLatestNewsLoading] = useState(true);

    let category;
    if(widget.data.category === 'all') {
        category = "";
    }else{
        category = widget.data.category;
    }

    const fetchLatestNews = async() => {
        let latestNews;
        if(category !== "") {
            latestNews = await ApiService.getLatestNewsByCategory(category);
            latestNews = latestNews.filter(news => news.is_sticky === false);
        } else {
            latestNews = await ApiService.getLatestNews();
            latestNews = latestNews.filter(news => news.is_sticky === false);
        }
        setLatestNews(latestNews);
        setIsLatestNewsLoading(false);
    }

    const fetchStickyNews = async() => {
        const stickyNews = await ApiService.getStickyNews();
        if(stickyNews) {
            setStickyNews(new NewsClass(stickyNews));
        }        
        setIsStickyNewsLoading(false);
    }

    useEffect(() => {
        fetchStickyNews();
        fetchLatestNews();
    }, []);

    
    if(isStickyNewsLoading) return <Container style={{ padding: "50px", height: "100vh" }}></Container>;
    if(isLatestNewsLoading) return <Container style={{ padding: "50px", height: "100vh" }}></Container>;
    return <>
        {stickyNews && <>
            <Container key={stickyNews.slug} fluid id="homepage-news-wrapper">
                <Container className={widget.data.class}>
                    <Row>
                        <Col lg={{offset: 1, order: 2, size: 6}}>
                            <img className="sticky-news-img img-fluid" width="648" height="432" fetchpriority="high" alt="Copertina News" src={stickyNews.image === 'media/news/default.jpg' ? (env.BACKEND_BASE_URL + 'media/news/default.webp') : (env.BACKEND_BASE_URL + stickyNews.image)} title={stickyNews.title} />
                        </Col>
                        <Col lg={{order: 1, size: 5}}>
                            <Card className="sticky-news-wrapper">
                                <CardBody className="pb-5">
                                    <CardCategory date={moment(stickyNews.date_news).format('DD/MM/YYYY')} iconName="it-horn">
                                        {stickyNews.category.name}
                                    </CardCategory>
                                    <CardTitle tag="h3">
                                        <a className="news-title-link" href={'/novita/'+ stickyNews.category.slug + '/' + stickyNews.slug + '/'}>{stickyNews.title}</a>
                                    </CardTitle>
                                    <div className="mb-3">
                                        {parse((stickyNews.content.description.description).replace(/(<([^>]+)>)/gi, "").substring(0, 100) + '...')}
                                    </div>
                                    {stickyNews.tags && stickyNews.tags.map((tag) => 
                                        <div key={tag.slug} className={"chip chip-simple chip-primary me-2 mb-2"}>
                                            <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                        </div>
                                    )}                                       
                                    <CardReadMore className="sticky-read-more" href="/novita/" text="Tutte le novità" />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
        }
        {!stickyNews && <Container className="mt-5"><h2>Ultime Notizie</h2></Container>}

        {latestNews && latestNews.length > 0 && <>
            <Container fluid className={stickyNews ? "latest-news-wrapper section section-muted pb-5 pt-0" : "latest-news-wrapper section section-muted pb-5"}>
                <Container>
                    <div className="card-wrapper card-teaser-wrapper card-overlapping card-teaser-wrapper-equal card-teaser-block-3">
                    
                    {latestNews && latestNews.slice(0, 6).map((news) => {
                        return (                       
                                <Card key={news.slug} className="card-teaser-image card-flex no-after rounded shadow latest-news-card" noWrapper teaser>
                                    <div className="card-image-wrapper with-read-more pb-5">
                                        <CardBody className="p-4">
                                            <CardCategory date={moment(news.date_news).format('DD/MM/YYYY')} iconName="it-horn"> 
                                                {news.category.name} 
                                            </CardCategory>
                                            <CardTitle className="fw-semibold"> <a className="news-title-link" href={'/novita/'+ news.category.slug + '/' + news.slug + '/'}>{news.title}</a> </CardTitle>
                                            <div className="mt-2 news-description">
                                                {parse((news.content.description.description).substring(0, 100).replace(/(<([^>]+)>)/gi, "") + '...')}
                                            </div>
                                        </CardBody>
                                    </div>
                                    <a className="ps-4 read-more" href={'novita/' + news.category.slug + '/' + news.slug + '/'}>Leggi di più <Icon icon="it-arrow-right" /></a>
                                </Card>                   
                        )
                    })}
                    </div>
                </Container>
            </Container>
        </>        
        }
        
    </>
}

export default WidgetNewsHome;