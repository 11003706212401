/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Icon, AvatarWrapper, AvatarExtraText, AvatarIcon, Card, CardBody, CardText, CardTitle, CardReadMore, Spinner } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import authApiService from "pa_kit/services/api";
import { SectorClass, SectorContentSections } from '../models/sector';
import { ActaAuthContext } from "pa_kit/auth";
import env from '../../../../env';


const WidgetSector = (props) => {
    const auth = useContext(ActaAuthContext);
    const auth_config = auth.organization.active_licenses?.services?.auth;

    const [ widget ] = useState(props.widget);

    const [ sectors, setSectors ] = useState();
    const [ offices, setOffices ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let id_sector;

    if(widget.data.id_sector) {
        id_sector = widget.data.id_sector;
    }

    const fetchSectors = async() => {
        if(id_sector !== undefined) {
            const sectorData = await authApiService.getSector(id_sector);
            setSectors(new SectorClass(sectorData));
            if(sectors) {
                const officesData = await authApiService.getOffices();
                const offices = officesData.filter(office => office.sectors.some(sector => sector.id === id_sector));
                setOffices(offices);
            }
        } else {
            const sectorData = await authApiService.getSectors();
            setSectors(sectorData);
        }

        setIsLoading(false);
    }    

    useEffect(() => {
        fetchSectors();
    }, [isLoading]);

    if(id_sector === undefined) return <>
        <h2 className="sector-page-title mt-5">{widget.data.label ? widget.data.label : 'Elenco Settori'}</h2>
        <Container fluid className={widget.data.class + '-sector page-sector-content'}>
            <Row>
            {sectors && sectors.map((sector) => 
                <Col xs='12' lg='4' className="mt-4" key={"key_sectorItem_" + sector.id}>                    
                    <Card className="sector-card border border-light shadow-sm">
                        <CardBody>
                            <CardTitle tag='h3' className='big-heading'>
                                <a href={'/' + sector.slug + '/'} className="text-decoration-none me-2" title={sector.name}>{sector.name}</a>
                            </CardTitle>
                            <CardText>
                                {sector.content.excerpt ? parse(sector.content.excerpt.description) : ''}
                            </CardText>
                            <CardReadMore href={'/' + sector.slug + '/'} text='Vai alla pagina' iconName='it-arrow-right' />
                        </CardBody>
                    </Card>
                </Col>
            )}
            </Row>
        </Container>
    </>

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;
    return <>
        <Container className="mb-5">
            <Row className="sector-content-wrapper">
                <Col lg={4} className="sector-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(SectorContentSections).map((key, index) => ((sectors?.content[key] && (Array.isArray(sectors?.content[key]) ? (sectors?.content[key].length > 0) : (sectors?.content[key].description !== '' && sectors?.content[key].description !== null))) || (key === 'staff' && sectors[key].length > 0) || (key === 'offices' && offices?.length > 0)) &&
                                    <NavItem key={"key_sectorlink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(SectorContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="sector-section-content">
                    {Object.keys(SectorContentSections).map((key, index) => (sectors?.content[key] || key === 'staff' || key === 'offices') &&
                        <div key={"key_sectorSection_" + index} className="mb-4">
                            
                            {key !== 'staff' && key !== 'offices' && sectors?.content[key].description &&  <>  
                                <h4 className="sector-content-title" id={key}>{ Object.values(SectorContentSections)[index] }</h4>                          
                                <div className="sector-content-description">
                                    { parse(sectors.content[key].description) }
                                </div>
                            </>}

                            {key === 'offices' && offices?.length > 0 && <>
                                <h4 className="sector-content-title" id={key}>{ Object.values(SectorContentSections)[index] }</h4>                  
                                <Row className="mb-2">
                                    {offices && offices?.map((office) =>
                                    <Col lg="6" key={"key_officeItem_" + office.id} className="mb-2">
                                        <Card className="office-card-box">
                                            <CardBody className="office-card">
                                                <Row>
                                                    <Col lg="8" xs="8">
                                                        <CardTitle tag='h5' className="mb-1">
                                                            <a href={'/' + office.slug + '/'} title={office.name}>{office.name}</a>
                                                        </CardTitle>
                                                        <CardText>
                                                            Ufficio
                                                        </CardText>
                                                    </Col>
                                                    <Col lg={{size: 3, offset: 1}} xs="4">
                                                        <Icon icon="it-pa" size="lg" />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    )}
                                </Row>
                                </>      
                            }

                            {key === 'staff' && sectors[key].length > 0 && <>
                                <h4 className="sector-content-title" id={key}>{ Object.values(SectorContentSections)[index] }</h4>
                                <Row> 
                                    {sectors?.staff.map((staff) =>
                                    <Col lg="6" key={"key_staffSection_" + staff.id} className="staff-card-col">
                                        <AvatarWrapper className="staff-card" extra='text'>
                                            <AvatarIcon size='xl'>
                                                <img src={staff.image ? auth_config?.backend_url + staff.image : auth_config?.backend_url + 'media/organization/default_avatar.jpg'} alt={staff.first_name + ' ' + staff.last_name} />
                                            </AvatarIcon>
                                            <AvatarExtraText>
                                                <h4> <a href={'/' + staff.slug + '/'}>{staff.first_name + ' ' + staff.last_name}</a> </h4>
                                                <p>{staff.role}</p>
                                            </AvatarExtraText>
                                        </AvatarWrapper> 
                                    </Col>
                                    )} 
                                </Row>   
                                </>                          
                            }

                            {key === 'documents' && sectors.content[key].length > 0 && <>
                                <h4 className="sector-content-title" id={key}>{ Object.values(SectorContentSections)[index] }</h4> 
                                {sectors.content[key].map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                <div className="mt-3">{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={auth_config?.backend_url + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                                )}
                                </>
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(sectors.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>
}

export default WidgetSector;