/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Icon, Spinner, Hero, HeroBackground } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import ApiService from "../../../services/api";
import { CustomWidgetClass } from '../models/custom_widget';
import WidgetGallery from 'apps/galleries/components/widget_gallery';
import { WidgetClass } from 'apps/pages/models/widget';
import env from '../../../env';
import ReadDir from 'services/readdir';


const WidgetCustom = (props) => {

    const [ widget ] = useState(props.widget);

    const [ customWidget, setCustomWidget ] = useState();    
    const [ widgetGallery, setWidgetGallery ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let id_custom_widget;

    if(widget.data.id_custom_widget) {
        id_custom_widget = widget.data.id_custom_widget;
    } 

    const fetchCustomWidget = async() => {       
        const customWidgetData = await ApiService.getCustomWidget(id_custom_widget);
        setCustomWidget(new CustomWidgetClass(customWidgetData));
        setWidgetGallery(new WidgetClass({
            name: "CustomWidgetGallery",
            class: "custom-widget-gallery",
            id_gallery: customWidgetData.gallery,
            type: 'custom-widget-gallery'
        }));        
        setIsLoading(false);
    }    

    useEffect(() => {
        fetchCustomWidget();
    }, []);

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;

    return <>
        {customWidget.image && customWidget.image !== '' &&
            <>               
            <Hero small className="custom-widget-hero-wrapper">
                <HeroBackground src={env.BACKEND_BASE_URL + customWidget.image} title={customWidget.image_description || customWidget.name} alt={customWidget.image_description || customWidget.name} />
            </Hero>
            
            <Container>
                <Row className="text-center">
                    <Col>
                        <p className="news-image-description mt-4">{customWidget.image_description}</p>
                    </Col>
                </Row>
            </Container>
            </>
        } 
        <Container className="mb-5 mt-4">
            <Row className="custom-widget-content-wrapper">
                <Col lg={4} className="custom-widget-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {customWidget && customWidget.content.items.map((item, index) => 
                                    <NavItem key={"key_itemlink_" + index}>
                                        <NavLink href={"#" + customWidget.slug + index} className="sidebar-nav-link">{item.name}</NavLink>
                                    </NavItem>
                                )}
                                {customWidget && customWidget.gallery !== '' &&
                                    <NavItem key={"key_itemlink_gallery"}>
                                        <NavLink href={"#" + customWidget.slug + 'gallery'} className="sidebar-nav-link">Immagini</NavLink>
                                    </NavItem>
                                }
                                {customWidget && (customWidget.content.documents.length > 0 || (customWidget.content.folders && customWidget.content.folders.length > 0)) &&
                                    <NavItem key={"key_itemlink_documents"}>
                                        <NavLink href={"#" + customWidget.slug + 'documents'} className="sidebar-nav-link">Documenti</NavLink>
                                    </NavItem>
                                }
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="custom-widget-section-content">
                    {customWidget && customWidget.content.items.map((item, index) => 
                        <div key={"key_itemSection_" + index} className="mb-4">
                            <h4 className="custom-widget-content-title" id={customWidget.slug + index}>{ item.name }</h4>
                            <div className="place-content-description">
                                { parse(item.description) }
                            </div>
                        </div>
                    )}                    

                    {customWidget.gallery !== '' && 
                        <div key={"key_itemSection_gallery"} className="mb-4">
                            <h4 className="custom-widget-content-title" id={customWidget.slug + 'gallery'}>Immagini</h4>
                            <WidgetGallery widget={widgetGallery} />
                        </div>
                    }

                    {(customWidget.content.documents.length > 0 || (customWidget.content.folders && customWidget.content.folders.length > 0)) && 
                        <div key={"key_itemSection_documents"} className="mb-4">
                            <h4 className="custom-widget-content-title" id={customWidget.slug + 'documents'}>Documenti</h4>
                            {customWidget.content.documents.length > 0 && customWidget.content.documents.map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                    <div>{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={env.BACKEND_BASE_URL + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                            )}

                            {customWidget.content.folders && customWidget.content.folders.length > 0 && 
                                <div key={"key_itemSection_folders"} className="mb-4">
                                    {customWidget.content.folders.map((folder, i) =>
                                        <React.Fragment key={"key_folderSection_" + i}>
                                            <ReadDir folder={folder} index={i} />                                
                                        </React.Fragment>
                                    )}
                                </div>
                            }
                        </div>
                    } 

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(customWidget.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>
}

export default WidgetCustom;