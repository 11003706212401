/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Spinner } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import ApiService from "../../../services/api";
import { GalleryClass, GalleryContentSections } from '../models/gallery';
import LightboxComponent from './lightbox';
import env from '../../../env';

const Gallery = (props) => {

    const gallery = props.gallery;

    const [ isOpen, setOpen ] = useState(false);
    const [ currentImageIndex, setCurrentIndex ] = useState(0);

    const handleClick = (index) => {
        setCurrentIndex(index);
        setOpen(true);
    }

    if(!gallery) return <div></div>;
    return (
        <Row> 
            <Col>
                <p>{gallery.description}</p>
                <Row className="mb-5">
                {gallery.attachments.map((image, index) => {
                    return (
                        <React.Fragment key={'galleryElement_' + index}>                                             
                            <Col lg="3" md="3" sm="6" className="pb-3">
                                <img src={env.BACKEND_BASE_URL + image.thumb} alt={image.name}
                                    onClick={() => handleClick(index)}
                                    className="gallery-img"
                                />
                            </Col>                                                                        
                        </React.Fragment>
                    )                                        
                })} 
                </Row>
                <LightboxComponent gallery={gallery} isOpen={isOpen} currentImageIndex={currentImageIndex} setCurrentIndex={setCurrentIndex} onClose={() => setOpen(false)} />
            </Col>
        </Row> 
    );
}


const WidgetGallery = (props) => {

    const [ widget ] = useState(props.widget);

    const [ gallery, setGallery ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let id_gallery;
    let type;

    if(widget.data.id_gallery) {
        id_gallery = widget.data.id_gallery;
    }

    if(widget.data.type) {
        type = widget.data.type;
    }

    const fetchGallery = async() => {        
        const galleryData = await ApiService.getGallery(id_gallery);
        setGallery(new GalleryClass(galleryData));
        setIsLoading(false);             
    }    

    useEffect(() => {
        fetchGallery();
    }, []);

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;


    if(type === 'place-gallery' || type === 'custom-widget-gallery') return <>                          
        {gallery?.content.images.map((element, index) => {
            return <Gallery gallery={element} key={'galleryContent_' + index}  />                                                                          
        })}                
    </>

    return <>
        <Container className="mb-5">
            <Row className="gallery-content-wrapper">
                <Col lg={4} className="gallery-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(GalleryContentSections).map((key, index) => ((gallery?.content[key] && gallery?.content[key].description !== '' && gallery?.content[key].description !== null && key !== 'images') || (key === 'images' && gallery.content[key].length > 0)) &&
                                    <NavItem key={"key_gallerylink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(GalleryContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="gallery-section-content">
                    {Object.keys(GalleryContentSections).map((key, index) => (gallery?.content[key] || key === 'images') &&
                        <div key={"key_gallerySection_" + index} className="mb-4">
                            
                            {key !== 'images' && gallery?.content[key].description && <>
                                <h4 className="gallery-content-title" id={key}>{ Object.values(GalleryContentSections)[index] }</h4>                            
                                <div className="gallery-content-description">
                                    { parse(gallery.content[key].description) }
                                </div>
                                </>
                            }

                            {key === 'images' && gallery?.content[key].length > 0 && <>
                                <h4 className="gallery-content-title" id={key}>{ Object.values(GalleryContentSections)[index] }</h4>                 
                                {gallery?.content[key].map((element, index) => {
                                    return <Gallery gallery={element} key={'galleryContent_' + index}  />                                                                          
                                })}   
                                </>         
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(gallery.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>
}

export default WidgetGallery;