import { WidgetClass } from './widget';
import { TagClass } from 'pa_kit/apps/tags/models/tag';
import ApiService from '../../../services/api';

export class PageClass {

    constructor(data) {
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.meta_description = data.meta_description;
        this.meta_img = data.meta_img;
        this.content = new WidgetClass(data.content);
        this.files = data.files;
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.main_section = data.main_section_data || '';
        this.parent = data.parent_data?.slug || '';
    }

    hasParent() {
        return this.parent && this.parent !== '';
    }

    async getBreadcrumb() {
        let breadcrumb = [];        
        if(this.hasParent()) {
            let breadcrumbResult = await ApiService.getPage(this.parent);
            let parent = new PageClass(breadcrumbResult);
            if(parent.hasParent()) {
                breadcrumb = await parent.getBreadcrumb();
            } else {
                breadcrumb.push([parent.title, parent.slug]);
            }                    
        }       

        breadcrumb.push([this.title, this.slug]);        

        return breadcrumb;
    }
}