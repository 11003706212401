// @ts-nocheck
import { Card, CardBody, CardTitle, CardText, Container, Icon, Row, Col, Spinner } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import ApiService from '../../../services/api';
import Pagination from 'pa_kit/pagination';
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';

const WidgetDocuments = (props) => {

    const [ widget ] = useState(props.widget);

    const [ documentsList, setDocumentsList ] = useState();
    const [ currentDocuments, setCurrentDocuments ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const [ paginationState, setPaginationState ] = useState({
        currentPage: 1,
        documentsPerPage: widget.data.show_number
    });

    const handlePageChange = (pageNumber) => {
        setPaginationState({ ...paginationState, currentPage: pageNumber });
    };

    let category;
    let id_tag;

    let show = widget.data.show_number;

    let page = props.page || '';

    if(widget.data.category) {
        if(widget.data.category === 'all') {
            category = "";
        } else {
            category = widget.data.category;
        } 
    }

    if(widget.data.id_tag) {
        id_tag = widget.data.id_tag;
    } 

    const fetchDocumentsList = async() => {  
        let documentsData;

        if(!documentsList) {

            if(category !== undefined) {
                documentsData = await ApiService.getDocumentsByCategory(category);
                // documentsData = documentsData.filter(doc => doc.category.id === category);
                
            } else if (id_tag !== undefined) {
                documentsData = await ApiService.getDocumentsByTag(id_tag);
                // documentsData = documentsData.filter(doc => doc.tags.some(tag => tag.id === id_tag));
            }

            setDocumentsList(documentsData);
            setIsLoading(false);

        } else {
            documentsData = documentsList;
        }

        const indexOfLastElement = paginationState.currentPage * paginationState.documentsPerPage;
        const indexOfFirstElement = indexOfLastElement - paginationState.documentsPerPage;
        const currentDocuments = documentsData.slice(indexOfFirstElement, indexOfLastElement);
        setCurrentDocuments(currentDocuments);
    }

    useEffect(() => {
        fetchDocumentsList();
    }, [paginationState]);

    if(isLoading) return <Container>{page !== 'tag' && <Spinner active />}</Container>
    if(documentsList && documentsList.length === 0) return page !== 'tag' && <Container>{category !== undefined ? '' : 'Nessun Risultato'}</Container>

    if(show > 3) return <>
        {page === 'tag' && documentsList && documentsList.length !== 0 && 
            <Container className="mt-5 pt-5">
                <h2 className="news-by-tags-title mb-4 px-1">Documenti</h2>  
            </Container>
        }
        <h2 className="documents-page-title mt-2">{widget.data.label ? widget.data.label : 'Elenco Documenti'}</h2>
        <Container id="documents-elements-wrapper" fluid className={widget.data.class + '-documents page-documents-content'}>
            <Row>
            {currentDocuments && currentDocuments.map((doc) => {
                return (
                    <Col xs='12' lg='4' className="mt-4" key={"key_cardItem_" + doc.id}>                    
                        <Card className="menu-card document-card-box border border-light shadow-sm">
                            <CardBody>
                                <p className="document-category"><Icon icon="it-note" className="document-icon" /> {doc.category.name}</p>
                                <CardTitle tag='h3' className='big-heading'>
                                    <a href={'/documenti-e-dati/documento/' + doc.slug + '/'} className="text-decoration-none me-2" title={doc.name}> {doc.name}</a>
                                </CardTitle>
                                <CardText>
                                    {parse((doc.content.description.description).replace(/(<([^>]+)>)/gi, "").substring(0, 100) + (doc.content.description.description.length > 100 ? '...' : ''))}
                                </CardText>
                                <div className="mt-2 mb-3 document-date">
                                    <strong>Data Pubblicazione:</strong> {moment(doc.date_published).format('D MMMM Y')}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>                   
                );
            })}
            </Row>
            <Pagination
                elementsPerPage={paginationState.documentsPerPage}
                totalElements={documentsList && documentsList.length}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                type="documents"
            />  
        </Container>        
    </>;

    return <>
        <h2 className="documents-page-title mt-5">{widget.data.label ? widget.data.label : 'Elenco Documenti'}</h2>
        <Container fluid className={widget.data.class + '-documents page-documents-content'}>
            <Row>
            {documentsList && documentsList.slice(0, show).map((doc) => {
                return (
                    <Col xs='12' lg='4' className="mt-4" key={"key_cardItem_" + doc.id}>                    
                        <Card className="menu-card document-card-box border border-light shadow-sm">
                            <CardBody>
                                <p className="document-category"><Icon icon="it-note" className="document-icon" /> {category.name}</p>
                                <CardTitle tag='h3' className='big-heading'>
                                    <a href={'/documenti-e-dati/documento/' + doc.slug + '/'} className="text-decoration-none me-2" title={doc.name}> {doc.name}</a>
                                </CardTitle>
                                <CardText>
                                    {parse((doc.content.description.description).replace(/(<([^>]+)>)/gi, "").substring(0, 100) + (doc.content.description.description.length > 100 ? '...' : ''))}
                                </CardText>
                                <div className="mt-2 mb-3 document-date">
                                    <strong>Data Pubblicazione:</strong> {moment(doc.date_published).format('D MMMM Y')}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>                   
                );
            })}
            </Row>
        </Container>        
    </>;
}

export default WidgetDocuments;