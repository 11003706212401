/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Icon, Card, CardCategory, CardBody, CardText, CardTitle, CardReadMore, Spinner, Hero, HeroBackground } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import ApiService from "../../../services/api";
import { PlaceClass, PlaceContentSections } from '../models/place';
import WidgetGallery from 'apps/galleries/components/widget_gallery';
import { WidgetClass } from 'apps/pages/models/widget';
import Pagination from 'pa_kit/pagination';
import env from '../../../env';


const WidgetPlace = (props) => {

    const [ widget ] = useState(props.widget);

    const [ places, setPlaces ] = useState();    
    const [ widgetGallery, setWidgetGallery ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const [ currentPlaces, setCurrentPlaces ] = useState();

    const [ paginationState, setPaginationState ] = useState({
        currentPage: 1,
        placesPerPage: 6
    });

    const handlePageChange = (pageNumber) => {
        setPaginationState({ ...paginationState, currentPage: pageNumber });
    };

    let placeSlug;
    let category;
    let id_tag;

    let page = props.page || '';

    if(widget.data.place_slug) {
        placeSlug = widget.data.place_slug;
    }

    if(widget.data.category) {
        category = widget.data.category;
    }

    if(widget.data.id_tag) {
        id_tag = widget.data.id_tag;
    } 

    const fetchPlaces = async() => {
        let placeData;

        if(!places) {

            if(placeSlug !== undefined) {
                placeData = await ApiService.getPlace(placeSlug);
                setPlaces(new PlaceClass(placeData));
                setWidgetGallery(new WidgetClass({
                    name: "PlaceGallery",
                    class: "place-gallery",
                    id_gallery: placeData.gallery,
                    type: 'place-gallery'
                }));
                setIsLoading(false);
            } else if(id_tag !== undefined) {
                placeData = await ApiService.getPlaces();            
                placeData = placeData.filter(place => place.tags.some(tag => tag.id === id_tag));    
                setPlaces(placeData);
                setIsLoading(false);
            } else {
                placeData = await ApiService.getPlaces();
                if(category !== 'all') {
                    if(category === 'sticky') {
                        placeData = placeData.filter(place => place.is_sticky === true);
                    } else {    
                        placeData = placeData.filter(place => place.category.id === category);
                    }                 
                    setPlaces(placeData);
                    setIsLoading(false);
                }            
            }
            
        } else {
            placeData = places;
        }

        if(placeSlug === undefined) {
            const indexOfLastElement = paginationState.currentPage * paginationState.placesPerPage;
            const indexOfFirstElement = indexOfLastElement - paginationState.placesPerPage;
            const currentPlaces = placeData.slice(indexOfFirstElement, indexOfLastElement);
            setCurrentPlaces(currentPlaces);
        }        
        
    }    

    useEffect(() => {
        fetchPlaces();
    }, [isLoading, paginationState]);

    if(isLoading) return <Container>{page !== 'tag' && <Spinner active />}</Container>;

    if(category !== undefined || id_tag !== undefined) return <>
        {page === 'tag' && places && places.length !== 0 &&
            <Container className="mt-5 pt-5">
                <h2 className="news-by-tags-title mb-4 px-1">Luoghi</h2>  
            </Container>
        }
        <h2 className="places-page-title">{widget.data.label ? widget.data.label : 'Elenco Luoghi'}</h2>
        <Container fluid className={widget.data.class + '-places page-places-content'}>
            <Row id="places-elements-wrapper">
            {currentPlaces && currentPlaces.map((place) => 
                <Col xs='12' lg='4' className="mt-4" key={"key_cardItem_" + place.id}>                    
                    <Card className='card-img no-after shadow-sm'>
                    {place.image && place.image !== '' && 
                        <div className='img-responsive-wrapper'>
                            <div className='img-responsive img-responsive-panoramic'>
                                <figure className='img-wrapper'>
                                    <img alt="Copertina Luogo" className="img-fluid" src={env.BACKEND_BASE_URL + place.image || ''} title={place.name} />
                                </figure>
                            </div>
                        </div>
                    }   
                        <CardBody className="px-4">
                            <CardCategory iconName="it-map-marker-circle"> 
                                {place.category.name} 
                            </CardCategory>
                            <CardTitle className="fw-semibold"> <a className="news-title-link" href={'/luoghi/'+ place.category.slug + '/' + place.slug + '/'}>{place.name}</a> </CardTitle>
                            <div className="mt-2 news-description mb-5">
                                {parse((place.content.description.description).substring(0, 100).replace(/(<([^>]+)>)/gi, "")) + '...'}
                            </div>
                            <a className="read-more position-relative" href={'/luoghi/' + place.category.slug + '/' + place.slug + '/'}>Leggi di più <Icon icon="it-arrow-right" /></a>
                            <div className="mt-5">
                            {place.tags && place.tags.map((tag) => 
                                <div key={tag.slug} className={"chip chip-simple chip-primary me-2"}>
                                    <a className="chip-label" href={"/argomenti/" + tag.slug + '/'}>{tag.name}</a>
                                </div>
                            )} 
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            )}
            {places && places.length === 0 && page !== 'tag' && <Container>Nessun Risultato</Container>}
            </Row>
            {places && places.length > 0 &&
                <Pagination
                    elementsPerPage={paginationState.placesPerPage}
                    totalElements={places && places.length}
                    currentPage={paginationState.currentPage}
                    onPageChange={handlePageChange}
                    type="places"
                />
            }
            {widget.data.all_places_button && <center className="mt-3"><button href="/luoghi/" onClick={(e) => { e.preventDefault(); window.location.href='/luoghi/'; }} type="button" data-element="live-button-locations" className="btn btn-primary">Tutti i luoghi</button></center>}         
        </Container>
    </>

    if(placeSlug === undefined && id_tag === undefined) return <>
        <h2 className="places-page-title mt-5">{widget.data.label ? widget.data.label : 'Tutti i luoghi'}</h2>
        <Container fluid className={widget.data.class + '-places page-places-content'}>
            <Row>
            {currentPlaces && currentPlaces.map((place) => 
                <Col xs='12' lg='4' className="mt-4" key={"key_sectorItem_" + place.id}>                    
                    <Card className="places-card border border-light shadow-sm">
                        <CardBody className="px-4">
                            <CardTitle tag='h3' className='big-heading'>
                                <a href={'/' + place.slug + '/'} className="text-decoration-none me-2" title={place.name}>{place.name}</a>
                            </CardTitle>
                            <CardText>
                                {parse(place.content.description.description)}
                            </CardText>
                            <CardReadMore href={'/' + place.slug + '/'} text='Vai alla pagina' iconName='it-arrow-right' />
                        </CardBody>
                    </Card>
                </Col>
            )}
            </Row>
            <Pagination
                elementsPerPage={paginationState.placesPerPage}
                totalElements={places && places.length}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                type="places"
            />
        </Container>
    </>

    return <>
        {places.image && places.image !== '' &&               
            <Hero small>
                <HeroBackground src={env.BACKEND_BASE_URL + places.image} title={places.image_description || places.name} alt={places.image_description || places.name} />
            </Hero>                              
        } 
        <Container className="mb-5 mt-4">
            <Row className="places-content-wrapper">
                <Col lg={4} className="places-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(PlaceContentSections).map((key, index) => ((places?.content[key] && (Array.isArray(places?.content[key]) ? (places?.content[key].length > 0) : (places?.content[key].description !== '' && places?.content[key].description !== null))) || (key === 'address' && places[key] !== null && places[key] !== '') || (key === 'images' && places.gallery !== '' && places.gallery !== null)) &&
                                    <NavItem key={"key_placelink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(PlaceContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="places-section-content">
                    {Object.keys(PlaceContentSections).map((key, index) => (places?.content[key] || key === 'address' || key === 'images') &&
                        <div key={"key_placeSection_" + index} className="mb-4">
                            
                            {key !== 'address' && key !== 'images' && places?.content[key].description && <>
                                <h4 className="places-content-title" id={key}>{ Object.values(PlaceContentSections)[index] }</h4>                          
                                <div className="place-content-description">
                                    { parse(places.content[key].description) }
                                </div>
                                </>
                            }

                            {key === 'address' && places[key] !== '' && places[key] !== null && <>
                                <h4 className="places-content-title" id={key}>{ Object.values(PlaceContentSections)[index] }</h4>    
                                <div>{places.address}</div>
                                </>
                            }

                            {key === 'images' && places.gallery !== null && places.gallery !== '' && <>
                                <h4 className="places-content-title" id={key}>{ Object.values(PlaceContentSections)[index] }</h4>
                                <WidgetGallery widget={widgetGallery} />
                                </>
                            }

                            {key === 'documents' && places.content[key].length > 0 && <>
                                <h4 className="places-content-title" id={key}>{ Object.values(PlaceContentSections)[index] }</h4>   
                                {places.content[key].map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                <div className="mt-3">{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={env.BACKEND_BASE_URL + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                                )}
                                </>
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(places.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>
}

export default WidgetPlace;