import { CategoryClass } from "apps/categories/models/category";
import { OfficeClass } from "pa_kit/apps/offices/models/office";
import { TagClass } from "pa_kit/apps/tags/models/tag";

export const DocumentContentSections =  {
    description: "Descrizione",
    office: "Ufficio di riferimento",
    files: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class DocumentClass {

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.description = data.description;
        this.category = new CategoryClass(data.category_data);
        this.date_published = data.date_published;
        this.last_update = data.last_update;
        this.content = data.content;
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.user = data.user;
    }   
}