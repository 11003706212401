/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { Row, Container, Col, Card, CardBody, CardTitle, CardText, CardReadMore, Hero, HeroBackground, Icon, LinkList, LinkListItem } from 'design-react-kit';
import authApiService from 'pa_kit/services/api';
import ApiService from '../../../services/api';
import { WidgetClass } from 'apps/pages/models/widget';
import WidgetTags from 'apps/tags/components/widget_tags';
import { LocalSettingsContext } from "services/local_settings";
import parse from 'html-react-parser';
import env from '../../../env';

const ArticlesByTags = (props) => {

    const settings = useContext(LocalSettingsContext);
    const localSettings = settings.localSettings;        
    const eventsParentCategorySlug = localSettings.events_parent_category === null ? 'novita' : localSettings.events_parent_category_data.slug

    const [ newsByTag, setNewsByTag ] = useState();
    const [ eventsByTag, setEventsbyTag ] = useState();
    const [ documentsbyTag, setDocumentsByTag ] = useState();

    const fetchNews = async() => {
        const news = await ApiService.getLatestNewsByTag(props.tag.id);
        setNewsByTag(news);
    }

    const fetchEvents = async() => {
        const events = await ApiService.getLatestEventsByTag(props.tag.id);
        setEventsbyTag(events);
    }

    const fetchDocuments = async() => {
        const documents = await ApiService.getLatestDocumentsByTag(props.tag.id);
        setDocumentsByTag(documents);
    }

    useEffect(() => {
        fetchNews();
        fetchEvents();
        fetchDocuments();
    }, []);

    return <>
        <LinkList>
        {newsByTag && newsByTag.slice(0,3).map((news, i) =>            
            <LinkListItem key={"key_newsByTagsItem_" + i} href={'/novita/' + news.category.slug + '/' + news.slug + '/'} title={news.title} className="tags-home-link pb-2">
                <Icon icon="it-horn" className="tags-home-icon" /> {news.title}
            </LinkListItem>            
        )}
        </LinkList>

        <LinkList>
        {eventsByTag && eventsByTag.slice(0,3).map((event, i) =>            
            <LinkListItem key={"key_eventByTagsItem_" + i} href={eventsParentCategorySlug === 'novita' ? '/novita/eventi/'+ event.category.slug + '/' + event.slug : '/eventi/'+ event.category.slug + '/' + event.slug + '/'} title={event.title} className="tags-home-link pb-2">
                <Icon icon="it-calendar" className="tags-home-icon" /> {event.title}
            </LinkListItem>            
        )}
        </LinkList>

        <LinkList>
        {documentsbyTag && documentsbyTag.slice(0,3).map((doc, i) =>            
            <LinkListItem key={"key_docByTagsItem_" + i} href={'/documenti-e-dati/documento/' + doc.slug + '/'} title={doc.name} className="tags-home-link pb-2">
                <Icon icon="it-note" className="tags-home-icon" /> {doc.name}
            </LinkListItem>            
        )}
        </LinkList>
    </>;    
}

const WidgetTagsHome = (props) => {
    
    const [ widget ] = useState(props.widget);

    const [ stickyTags, setStickyTags ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const [ widgetTags ] = useState(new WidgetClass({
        name: "TagsSection",
        class: "all-tags-home-section",
        type: "tags",
        list_style: "chips" 
    }));

    const fetchTagList = async() => {        
        const tagListData = await authApiService.getTags();
        const stickyTagsData = tagListData.filter(tag => tag.is_sticky === true);
        setStickyTags(stickyTagsData);
        setIsLoading(false);
    }

    useEffect(() => {
        fetchTagList();
    }, []);

    return <>
        <Container fluid className="p-0 pb-5 mt-5 pt-5 mb-5 tags-home-wrapper">        
            <Hero overlap className="tags-home-hero-wrapper" overlay='dark'>
                <HeroBackground src={env.AUTH_BACKEND_BASE_URL + 'media/organization/argomenti_header.webp'} title="Argomenti in evidenza" alt="Argomenti in evidenza" className="tags-home-img" loading="lazy" />                
            </Hero>        
            <Container className="tags-home-content">
                <h3 className="tags-home-title">Argomenti in evidenza</h3>
            </Container>
            <Container className="mt-5">
                <Row>                    
                    {stickyTags && stickyTags.slice(0,3).map((tag, i) => 
                        <Col xs='12' lg='4' key={"key_stickyTagItem_" + i}>
                            <Card spacing className='card-bg card-big'>
                                <CardBody>       
                                    <div className="tags-home-icon-wrapper">
                                        <Icon icon='it-file' />
                                    </div>                  
                                    <CardTitle tag='h3' className='big-heading tags-home-tag-title mt-3'>
                                        {tag.name}
                                    </CardTitle>                                        
                                    <CardText>
                                        {parse(tag.description.replace(/(<([^>]+)>)/gi, ""))}  
                                    </CardText>
                                    <ArticlesByTags tag={tag} />
                                    <CardReadMore iconName='it-arrow-right' text='Esplora argomento' href={'/argomenti/' + tag.slug + '/'} />
                                </CardBody>
                            </Card>
                        </Col>
                    )}                    
                </Row>
            </Container>

            <Container className="mt-5 pt-5">
                <h2 className="news-page-category-title mb-4 px-1">Altri argomenti</h2>  
            </Container>

            <Container className="text-center mt-5 mb-5">
                <WidgetTags widget={widgetTags} />
            </Container>

        </Container>
    </>;
}

export default WidgetTagsHome;