import { GalleryClass } from "../../galleries/models/gallery";

export class CustomWidgetClass {

    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.image = data.image;
        this.image_description = data.image_description;
        this.content = data.content;
        this.gallery = data.gallery !== null ? new GalleryClass(data.gallery_data) : '';
        this.last_update = data.last_update;
    }
}