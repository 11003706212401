/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Chip, ChipLabel, Row, Container, Col, Card, CardBody, CardTitle, CardText, CardReadMore } from 'design-react-kit';
import ApiService from '../../../services/api';
import authApiService from 'pa_kit/services/api';
import { PageClass } from 'apps/pages/models/page';
import Pagination from 'pa_kit/pagination';
import parse from 'html-react-parser';

const WidgetTags = (props) => {
    
    const [ widget ] = useState(props.widget);

    const [ tagList, setTagList ] = useState();
    const [ currentTags, setCurrentTags ] = useState();
    const [ page, setPage ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);    

    const [ paginationState, setPaginationState ] = useState({
        currentPage: 1,
        tagsPerPage: 9 
    });

    const handlePageChange = (pageNumber) => {
        setPaginationState({ ...paginationState, currentPage: pageNumber });
    };

    const fetchTagList = async() => {
        if(widget.data.page_slug) {
            const pageData = await ApiService.getPage(widget.data.page_slug);
            setPage(new PageClass(pageData));
            if(page && page.tags) {
                setTagList(page.tags);
            }
        } else {
            let tagsListData = await authApiService.getTags();
            tagsListData = tagsListData.filter(tag => tag.is_active === true);
            const indexOfLastTag = paginationState.currentPage * paginationState.tagsPerPage;
            const indexOfFirstTag = indexOfLastTag - paginationState.tagsPerPage;
            const currentTags = tagsListData.slice(indexOfFirstTag, indexOfLastTag);
            setCurrentTags(currentTags);
            setTagList(tagsListData);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchTagList();
    }, [isLoading, paginationState]);

    let tagListStyle;
    switch(widget.data.list_style) {
        
        case 'cards':
            tagListStyle = <><Row id="tags-elements-wrapper">{currentTags && currentTags.map((tag) =>                
                    <Col xs='12' lg='4' className="mt-4" key={"key_cardTag_" + tag.slug}>                    
                        <Card className="tag-card border border-light shadow-sm">
                            <CardBody>
                                <CardTitle tag='h3' className='big-heading'>
                                    <a href={'/argomenti/' + tag.slug + '/'} key={"key_tagCard_" + tag.id} className="text-decoration-none me-2" title={tag.name} data-element="topic-element">{tag.name}</a>
                                </CardTitle>
                                <CardText>
                                    {parse(tag.description.replace(/(<([^>]+)>)/gi, ""))}
                                </CardText>
                                <CardReadMore href={'/argomenti/' + tag.slug + '/'} text='Leggi di più' iconName='it-arrow-right' />
                            </CardBody>
                        </Card>
                    </Col>
            )}</Row>
            <Pagination
                elementsPerPage={paginationState.tagsPerPage}
                totalElements={tagList && tagList.length}
                currentPage={paginationState.currentPage}
                onPageChange={handlePageChange}
                type="tags"
            />            
            </>;
            break;

        default:
            tagListStyle = <>{tagList && tagList.slice(0, 15).map((tag) =>
                <a href={'/argomenti/' + tag.slug + '/'} key={"key_tagCard_" + tag.id} className="text-decoration-none me-2" title={tag.name} data-element="topic-element">
                    <Chip simple color='primary' className="mb-2">
                        <ChipLabel>{tag.name}</ChipLabel>
                    </Chip>
                </a>                
            )}
            </>
        break;
    }

    if(widget.data.page_slug) 
        return  <Container fluid className={widget.data.list_style ==='chips' ? widget.data.class + '-tags page-tags-content page-tags-side ms-lg-5 ps-lg-5' : widget.data.class + '-tags page-tags-content page-tags-cards'}>
                    {tagList && tagList.length > 0 && <Row><span className="mb-2 small">Argomenti</span></Row>}
                    {tagListStyle}
                </Container>;

    return<>                
        <Container fluid className={widget.data.class + '-tags page-tags-content'}>
            {tagListStyle}
        </Container>
        {widget.data.list_style === 'chips' &&
            <Container>
                <a href="/argomenti/" className="btn btn-primary mt-3" title="Visualizza tutti gli argomenti">Tutti gli argomenti</a>
            </Container>
        }
    </>
}

export default WidgetTags;