/* eslint-disable array-callback-return */
// @ts-nocheck
import React, { useContext, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import 'css/index.css';
import App from './app';
import 'css/styles.css';
import 'font-awesome/css/font-awesome.min.css';

import { ActaAuthContext } from "pa_kit/auth";
import { LocalSettingsContext } from "services/local_settings";


const AppRoutes = () => {

    const auth = useContext(ActaAuthContext);
    const settings = useContext(LocalSettingsContext);    

    const theme = auth.theme;  

    const defaultTheme = {
        "--color-primary": theme.primary_color,
        "--color-secondary": theme.secondary_color,
        "--color-tertiary": theme.tertiary_color,
        "--color-quaternary": theme.quaternary_color,
    }    

    const applyTheme = (theme) => {
        Object.keys(theme).map((key) => {
            const value = theme[key];
            document.documentElement.style.setProperty(key, value);
        });
    };
    
    applyTheme(defaultTheme);

    const localSettings = settings.localSettings;    
    
    const user = auth.user;

    const isActagovWebsiteEditor = auth.user && auth.user.isActagovWebsiteEditor();
    const isActagovContentEditor = auth.user && auth.user.isActagovContentEditor();

    const Page = lazy(() => import('apps/pages/components/page'));
    const PageTags = lazy(() => import('apps/tags/components/page_tags'));
    const PageTagsSingle = lazy(() => import('apps/tags/components/page_tags_single'));
    const PageNews = lazy(() => import('apps/news/components/page_news'));
    const PageEvents = lazy(() => import('apps/events/components/page_events'));
    const PageNewsArticle = lazy(() => import('apps/news/components/page_news_article'));
    const PageNewsCategory = lazy(() => import('apps/news/components/page_news_category'));
    const PageEventsCategory = lazy(() => import('apps/events/components/page_events_category'));
    const PageEventsArticle = lazy(() => import('apps/events/components/page_events_article'));
    const PageDocuments = lazy(() => import('apps/documents/components/page_documents'));
    const PageDocumentsCategory = lazy(() => import('apps/documents/components/page_documents_category'));
    const PageDocumentSingle = lazy(() => import('apps/documents/components/page_document_single'));
    const PagePlaces = lazy(() => import('apps/places/components/page_places'));
    const PagePlacesCategory = lazy(() => import('apps/places/components/page_places_category'));
    const PagePlaceSingle = lazy(() => import('apps/places/components/page_place_single'));
    const PageSearch = lazy(() => import('apps/pages/components/page_search'));
    const PageServices = lazy(() => import('apps/services/components/page_services'));
    const ServiceView = lazy(() => import('apps/services/components/service'))
    const Login = lazy(() => import('pa_kit/components/login'));


    const Dashboard = lazy(() => import('admin/dashboard/dashboard'));
    const Main = lazy(() => import('admin/dashboard/main'));

    const CreateNews = lazy(() => import('admin/news/create_news'));
    const EditNews = lazy(() => import('admin/news/edit_news'));
    const CreateEvent = lazy(() => import('admin/events/create_event'));
    const CreateDocument = lazy(() => import('admin/documents/create_document'));
    const EditEvent = lazy(() => import('admin/events/edit_event'));
    const EditDocument = lazy(() => import('admin/documents/edit_document'));
    const PagesList = lazy(() => import('admin/pages/pages_list'));
    const CreatePage = lazy(() => import('admin/pages/create_page'));
    const EditPage = lazy(() => import('admin/pages/edit_page'));
    const MenusList = lazy(() => import('admin/menus/menus_list'));
    const CategoriesList = lazy(() => import('admin/categories/categories_list'));
    const TagsList = lazy(() => import('admin/tags/tags_list'));
    const OrganizationData = lazy(() => import('admin/organization/organization_data'));
    const StructuresList = lazy(() => import('admin/structures/structures_list'));
    const SectorsList = lazy(() => import('admin/sectors/sectors_list'));
    const OfficesList = lazy(() => import('admin/offices/offices_list'));
    const StaffList = lazy(() => import('admin/staff/staff_list'));
    const ActivitiesList = lazy(() => import('admin/activities/activities_list'));
    // const UsersList = lazy(() => import('admin/users/users_list'));
    const Settings = lazy(() => import('admin/settings/settings'));
    const Profile = lazy(() => import('admin/profile/profile'));
    const NewsList = lazy(() => import('admin/news/news_list'));
    const EventsList = lazy(() => import('admin/events/events_list'));
    const DocumentsList = lazy(() => import('admin/documents/documents_list'));
    const CreateMenu = lazy(() => import('admin/menus/create_menu'));
    const EditMenu = lazy(() => import('admin/menus/edit_menu'));
    const CreateCategory = lazy(() => import('admin/categories/create_category'));
    const EditCategory = lazy(() => import('admin/categories/edit_category'));
    const CreateTag = lazy(() => import('admin/tags/create_tag'));
    const EditTag = lazy(() => import('admin/tags/edit_tag'));
    const CreateStructure = lazy(() => import('admin/structures/create_structure'));
    const EditStructure = lazy(() => import('admin/structures/edit_structure'));
    const CreateSector = lazy(() => import('admin/sectors/create_sector'));
    const EditSector = lazy(() => import('admin/sectors/edit_sector'));
    const CreateOffice = lazy(() => import('admin/offices/create_office'));
    const EditOffice = lazy(() => import('admin/offices/edit_office'));
    const CreateStaff = lazy(() => import('admin/staff/create_staff'));
    const EditStaff = lazy(() => import('admin/staff/edit_staff'));
    const TextList = lazy(() => import('admin/texts/texts_list'));
    const CreateText = lazy(() => import('admin/texts/create_text'));
    const EditText = lazy(() => import('admin/texts/edit_text'));
    const GalleriesList = lazy(() => import('admin/galleries/galleries_list'));
    const CreateGallery = lazy(() => import('admin/galleries/create_gallery'));
    const EditGallery = lazy(() => import('admin/galleries/edit_gallery'));    
    const PlacesList = lazy(() => import('admin/places/places_list'));    
    const CreatePlace = lazy(() => import('admin/places/create_place'));    
    const EditPlace = lazy(() => import('admin/places/edit_place'));    
    const CustomWidgetsList = lazy(() => import('admin/custom_widgets/custom_widgets_list'));    
    const CreateCustomWidget = lazy(() => import('admin/custom_widgets/create_custom_widget'));    
    const EditCustomWidget = lazy(() => import('admin/custom_widgets/edit_custom_widget'));    
    const GDPRRegistryList = lazy(() => import('admin/gdpr_registry/gdpr_registry_list'));    
    const PageEvaluationList = lazy(() => import('admin/page_evaluation/page_evaluation_list'));    
    const PageEvaluationDetail = lazy(() => import('admin/page_evaluation/page_evaluation_detail'));    
    
    const FileManagerModal = lazy(() => import('admin/filemanager/filemanager'));    

    if(auth.isLoading || settings.isLocalSettingsLoading) return <div></div>;
    return <>
        <BrowserRouter>
            <Suspense fallback={<div></div>}>
                <Routes>
                    <Route path="/" element={<App />} >
                        <Route path="" element={<Page />} ></Route>
                        <Route path=":slug" element={<Page />} />
                        <Route path="argomenti/" element={<PageTags />} />
                        <Route path="argomenti/:tagSlug" element={<PageTagsSingle />} />
                        <Route path="novita/" element={<PageNews />} />
                        <Route path={localSettings.events_parent_category === null ? 'novita/eventi/': 'eventi/'} element={<PageEvents />} />
                        <Route path={localSettings.events_parent_category === null ? 'novita/eventi/:categorySlug': 'eventi/:categorySlug'} element={<PageEventsCategory />} />
                        <Route path={localSettings.events_parent_category === null ? 'novita/eventi/:categorySlug/:eventSlug': 'eventi/:categorySlug/:eventSlug'} element={<PageEventsArticle />} />
                        <Route path="novita/:categorySlug/" element={<PageNewsCategory />} />
                        <Route path="novita/:categorySlug/:newsSlug" element={<PageNewsArticle />} />
                        <Route path="documenti-e-dati/" element={<PageDocuments />} />
                        <Route path="documenti-e-dati/documento/:documentSlug" element={<PageDocumentSingle />} />
                        <Route path="documenti-e-dati/:categorySlug" element={<PageDocumentsCategory />} />                            
                        <Route path="luoghi" element={<PagePlaces />} />                            
                        <Route path="luoghi/:categorySlug" element={<PagePlacesCategory />} />                            
                        <Route path="luoghi/:categorySlug/:placeSlug" element={<PagePlaceSingle />} />                            
                        <Route path="servizi/" element={<PageServices />} /> 
                        <Route path="servizi/">
                            <Route path="" element={<PageServices />} />
                            <Route path=":slug" element={<PageServices />} />
                        </Route>
                        <Route path="servizio/">
                            <Route path=":slug" element={<ServiceView />} />
                        </Route>
                           
                        <Route path="ricerca" element={<PageSearch />} ></Route>
                        <Route path="login" element={<Login />} ></Route>                                      
                    </Route>

                    <Route path="/admin" element={(isActagovWebsiteEditor || isActagovContentEditor) ? <Dashboard /> : <Navigate to="/" />} >
                        <Route path="" element={<Main />} ></Route>
                        <Route path="pages" element={isActagovWebsiteEditor ? <PagesList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-page" element={isActagovWebsiteEditor ? <CreatePage /> : <Navigate to="/" />}></Route>
                        <Route path="edit-page/:pageSlug" element={isActagovWebsiteEditor ? <EditPage /> : <Navigate to="/" />}></Route>
                        <Route path="menus" element={isActagovWebsiteEditor ? <MenusList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-menu" element={isActagovWebsiteEditor ? <CreateMenu /> : <Navigate to="/" />}></Route>
                        <Route path="edit-menu/:menuID" element={isActagovWebsiteEditor ? <EditMenu /> : <Navigate to="/" />}></Route>
                        <Route path="categories" element={isActagovWebsiteEditor ? <CategoriesList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-category" element={isActagovWebsiteEditor ? <CreateCategory /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-category/:categorySlug" element={isActagovWebsiteEditor ? <EditCategory /> : <Navigate to="/" />} ></Route>
                        <Route path="tags" element={isActagovWebsiteEditor ? <TagsList /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-tag/:tagSlug" element={isActagovWebsiteEditor ? <EditTag /> : <Navigate to="/" />} ></Route>
                        <Route path="create-tag" element={isActagovWebsiteEditor ? <CreateTag /> : <Navigate to="/" />} ></Route>
                        <Route path="organization" element={isActagovWebsiteEditor ? <OrganizationData /> : <Navigate to="/" />} ></Route>
                        <Route path="structures" element={isActagovWebsiteEditor ? <StructuresList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-structure" element={isActagovWebsiteEditor ? <CreateStructure /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-structure/:structureID" element={isActagovWebsiteEditor ? <EditStructure /> : <Navigate to="/" />} ></Route>
                        <Route path="sectors" element={isActagovWebsiteEditor ? <SectorsList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-sector" element={isActagovWebsiteEditor ? <CreateSector /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-sector/:sectorID" element={isActagovWebsiteEditor ? <EditSector /> : <Navigate to="/" />} ></Route>
                        <Route path="offices" element={isActagovWebsiteEditor ? <OfficesList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-office" element={isActagovWebsiteEditor ? <CreateOffice /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-office/:officeID" element={isActagovWebsiteEditor ? <EditOffice /> : <Navigate to="/" />} ></Route>
                        <Route path="staff" element={isActagovWebsiteEditor ? <StaffList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-staff" element={isActagovWebsiteEditor ? <CreateStaff /> : <Navigate to="/" />} ></Route>
                        <Route path="edit-staff/:staffID" element={isActagovWebsiteEditor ? <EditStaff /> : <Navigate to="/" />} ></Route>
                        <Route path="activities" element={isActagovWebsiteEditor ? <ActivitiesList /> : <Navigate to="/" />} ></Route>
                        {/* <Route path="users" element={isActagovWebsiteEditor ? <UsersList /> : <Navigate to="/" />} ></Route> */}
                        <Route path="settings" element={isActagovWebsiteEditor ? <Settings /> : <Navigate to="/" />} ></Route>
                        <Route path="profile" element={<Profile />} ></Route>
                        <Route path="news" element={isActagovContentEditor ? <NewsList /> : <Navigate to="/" />} ></Route>
                        <Route path="events" element={isActagovContentEditor ? <EventsList /> : <Navigate to="/" />} ></Route>
                        <Route path="documents" element={isActagovContentEditor ? <DocumentsList /> : <Navigate to="/" />} ></Route>
                        <Route path="create-news" element={isActagovContentEditor ? <CreateNews /> : <Navigate to="/" />}></Route>
                        <Route path="create-event" element={isActagovContentEditor ? <CreateEvent /> : <Navigate to="/" />}></Route>
                        <Route path="create-document" element={isActagovContentEditor ? <CreateDocument /> : <Navigate to="/" />}></Route>
                        <Route path="edit-news/:newsSlug" element={isActagovContentEditor ? <EditNews /> : <Navigate to="/" />}></Route>
                        <Route path="edit-event/:eventSlug" element={isActagovContentEditor ? <EditEvent /> : <Navigate to="/" />}></Route>
                        <Route path="edit-document/:documentSlug" element={isActagovContentEditor ? <EditDocument /> : <Navigate to="/" />}></Route>
                        <Route path="texts" element={isActagovWebsiteEditor ? <TextList /> : <Navigate to="/" />}></Route>
                        <Route path="create-text" element={isActagovWebsiteEditor ? <CreateText /> : <Navigate to="/" />}></Route>
                        <Route path="edit-text/:textID" element={isActagovWebsiteEditor ? <EditText /> : <Navigate to="/" />}></Route>
                        <Route path="galleries" element={isActagovWebsiteEditor ? <GalleriesList /> : <Navigate to="/" />}></Route>
                        <Route path="create-gallery" element={isActagovWebsiteEditor ? <CreateGallery /> : <Navigate to="/" />}></Route>
                        <Route path="edit-gallery/:galleryID" element={isActagovWebsiteEditor ? <EditGallery /> : <Navigate to="/" />}></Route>
                        <Route path="places" element={isActagovWebsiteEditor ? <PlacesList /> : <Navigate to="/" />}></Route>
                        <Route path="create-place" element={isActagovWebsiteEditor ? <CreatePlace /> : <Navigate to="/" />}></Route>
                        <Route path="edit-place/:placeSlug" element={isActagovWebsiteEditor ? <EditPlace /> : <Navigate to="/" />}></Route>
                        <Route path="custom-widgets" element={isActagovWebsiteEditor ? <CustomWidgetsList /> : <Navigate to="/" />}></Route>
                        <Route path="create-custom-widget" element={isActagovWebsiteEditor ? <CreateCustomWidget /> : <Navigate to="/" />}></Route>
                        <Route path="edit-custom-widget/:customWidgetID" element={isActagovWebsiteEditor ? <EditCustomWidget /> : <Navigate to="/" />}></Route>
                        <Route path="gdpr-registry" element={isActagovWebsiteEditor ? <GDPRRegistryList /> : <Navigate to="/" />} ></Route>
                        <Route path="page-evaluation" element={isActagovWebsiteEditor ? <PageEvaluationList /> : <Navigate to="/" />} ></Route>
                        <Route path="page-evaluation/*" element={isActagovWebsiteEditor ? <PageEvaluationDetail /> : <Navigate to="/" />} ></Route>
                        <Route path="filemanager" element={<FileManagerModal />} ></Route>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    </>;
}

export default AppRoutes;

        
        