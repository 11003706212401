import React from 'react';
import { Icon, Row, Col } from "design-react-kit";
import Lightbox from 'react-spring-lightbox';
import env from '../../../env';

const LightboxHeader = (props) => {

    return (
        <Row className="px-4 py-3 lightbox-header">
            <Col>
                <p className="mb-0">{props.description}</p>                
            </Col>
            <Col>
                <Row>
                    <Col className="text-end">
                        {props.currentImageIndex + 1} / {props.images.length}
                    </Col>
                    <Col className="text-end">
                        <Icon icon='it-close-big' color="white" onClick={props.onClose} className="lightbox-icons" />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const LightboxFooter = (props) => {

    return (
        <Row className="px-4 py-3 lightbox-header">
            <Col className="text-center">
                <p className="mb-0">{props.images[props.currentImageIndex].name}</p>                
            </Col>
        </Row>
    );
}

const LightboxComponent = (props) => {  

    let currentImageIndex = props.currentImageIndex;
    let setCurrentIndex = props.setCurrentIndex;
    let isOpen = props.isOpen;
    let onClose = props.onClose;

    let images = [];

    if(props.gallery.attachments) {
        props.gallery.attachments.forEach(image => {
            images.push({src: env.BACKEND_BASE_URL + image.url, alt: image.name, name: image.name});
        });
    }

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    const gotoNext = () =>
        currentImageIndex + 1 < images.length &&
        setCurrentIndex(currentImageIndex + 1);

    return (
            <Lightbox
                isOpen={isOpen}
                onPrev={gotoPrevious}
                onNext={gotoNext}
                images={images}
                currentIndex={currentImageIndex}
                /* Add your own UI */
                renderHeader={() => (<LightboxHeader description={props.gallery.description} onClose={onClose} currentImageIndex={currentImageIndex} images={images} />)}
                renderFooter={() => (<LightboxFooter currentImageIndex={currentImageIndex} images={images} />)}
                renderPrevButton={currentImageIndex !== 0 ? () => (<Icon icon='it-chevron-left' color="white" size="xl" onClick={gotoPrevious} className="lightbox-icons" />) : () => (<div></div>)}
                renderNextButton={((images.length > 1) && (currentImageIndex < images.length - 1 || currentImageIndex === 0)) ? () => (<Icon icon='it-chevron-right' color="white" size="xl" onClick={gotoNext} className="lightbox-icons" />) : () => (<div></div>)}
                // renderImageOverlay={() => (<ImageOverlayComponent >)}

                /* Add styling */
                className="lightbox-wrapper"

                /* Handle closing */
                onClose={onClose}

                /* Use single or double click to zoom */
                singleClickToZoom

                /* react-spring config for open/close animation */
                pageTransitionConfig={{
                    from: { transform: "scale(1)", opacity: 0 },
                    enter: { transform: "scale(1)", opacity: 1 },
                    leave: { transform: "scale(1)", opacity: 0 },
                    config: { mass: 1, tension: 500, friction: 50 }
                }}
            />
    );
};

export default LightboxComponent;