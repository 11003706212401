import { GalleryClass } from "../../galleries/models/gallery";
import { CategoryClass } from '../../categories/models/category';
import { TagClass } from 'pa_kit/apps/tags/models/tag';

export const PlaceContentSections =  {
    description: "Descrizione",
    access: "Modalità di accesso",
    address: "Indirizzo",
    images: "Immagini",
    contacts: "Contatti",
    documents: "Documenti",
    more_info: "Ulteriori informazioni"
}

export class PlaceClass {    
  
    constructor(data){
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.image = data.image;
        this.image_description = data.image_description;
        this.content = data.content;
        this.address = data.address;
        this.last_update = data.last_update;
        this.category = new CategoryClass(data.category_data);
        this.gallery = data.gallery !== null ? new GalleryClass(data.gallery_data) : '';
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.is_sticky = data.is_sticky
    }
}

