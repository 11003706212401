// @ts-nocheck
import { Hero, HeroBackground, HeroBody, Icon } from 'design-react-kit';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import env from '../../../env';

const WidgetSearchHome = (props) => {

    const [ widget ] = useState(props.widget);

    const navigate = useNavigate();
    const [ searchKey, setSearchKey ] = useState("");

    const [isFocused, toggleFocus] = useState(false);

    const toggleFocusLabel = () => toggleFocus(true);
    const toggleBlurLabel = (e) => {
        if (e.target.value === '') {
            toggleFocus(!isFocused);
        }
    };

    const toSearch = () => {
        navigate('/ricerca/',{state: { search_in: widget.data.search_in, id: widget.data.search_id, key: searchKey } });
    }

    return <>
        <Hero overlay='dark' className="before-content-hero-wrapper">
            <HeroBackground src={env.AUTH_BACKEND_BASE_URL + 'media/organization/big_search_banner.webp'} title='Cerca nel sito' alt='Cerca nel sito' loading="lazy" />
            <HeroBody className="before-content-hero-body">                
                <div className='form-group search-home-form mb-3'>
                    <div className='input-group'>
                        <label htmlFor='input-group-search-home' className={isFocused ? 'active' : ''}>{widget.data.label}</label>
                        <input type='text' className={isFocused ? 'form-control focus--mouse' : 'form-control'} onFocus={toggleFocusLabel} onBlur={toggleBlurLabel} onChange={(e) => setSearchKey(e.target.value)} id='input-group-search-home' name='input-group-search-home' />
                        <div className='input-group-append'>
                            <button onClick={() => {toSearch()}}  className='btn btn-primary before-footer-btn' type='button' id='button-search-home' title="Cerca"><Icon icon='it-search' aria-hidden size='sm' color="white" /></button>
                        </div>
                    </div>
                </div>
                <div className="before-footer-button-container">
                    <a href="/novita/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Novità</a>
                    <a href="/uffici/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Uffici</a>
                    {/* <a href="/giunta/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Giunta</a> */}
                    <a href="/argomenti/comunicazione-istituzionale/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Comune</a>
                    <a href="/organi-di-governo/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Organi di Governo</a>
                    <a href="/documenti-e-dati/" className="btn btn-primary before-footer-btn main-pages-btn mb-2">Documenti e dati</a>
                </div>
            </HeroBody>
        </Hero> 
    </>;
}

export default WidgetSearchHome;