// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Row, Col, Icon } from 'design-react-kit';
import ApiService from './api';
import env from '../env';


const ReadDir = (props) => {

    const path = props.folder.folder_path;

    const [ files, setFiles ] = useState();

    const [ toggleState, setToggle ] = useState(props.folder.description ? (props.index === 0 ? true : false) : true);

    const fetchFolder = async() => {

        const filesList = await ApiService.getFolderFileList(path);
        setFiles(filesList);
    }

    useEffect(() => {
        fetchFolder();        
    }, []);

    return <>
        {props.folder.description !== '' &&
            <Row onClick={() => setToggle(!toggleState)}>
                <Col>     
                    <div className="mt-3 readdir-header">{toggleState === true && <Icon icon="it-collapse" color="readdir-icon-caret" />} {toggleState === false && <Icon icon="it-expand" color="readdir-icon-caret" />} { props.folder.description }</div>                        
                </Col>
            </Row>
        }
        
        {toggleState &&
            <Row className="mt-2">                                          
            {files && files.map((file, index) =>
                <Col key={"key_attachmentlink_" + index} lg={6} className="mb-2">                                            
                    <div className="attachment-box">
                        <a href={env.BACKEND_BASE_URL + file.path} className="text-decoration-none" title={file.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {file.name}</a>
                    </div>
                </Col>
            )}
            </Row>
        }        
    </>;
}
export default ReadDir;