// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'design-react-kit';
import authApiService from "pa_kit/services/api";
import { getCookie } from 'pa_kit/services/manage_cookies';

const WidgetPageRating = (props) => {

  const title = props.page_title;
  const variant = props.variant ?? 'boxed';
  const label = props.label ?? 'Quanto sono chiare le informazioni su questa pagina?';
	let pathname = props.page_url ?? window.location.pathname;

	if(pathname === '/') {
		pathname = '/homepage';
	}

	const cookieToken = getCookie('page_evaluation');

  const [ formData, setFormData ] = useState({
    page_title: title,
      path: pathname,
      rating: 0,
      feedback_value: 0,
      feedback_details: ''
  });

  const [ step, setStep ] = useState();
	const [ feedbackChecked, setFeedbackChecked ] = useState(false);

  const fetchPageEvaluationByIPAddress = async() => {        
    let pageEvaluation = await authApiService.checkPageEvaluationByIPAddress();       		
    pageEvaluation = pageEvaluation.filter(page_eva => page_eva.path === pathname);
    if(pageEvaluation.length > 0) {
        setStep(3);
    } else {
        setStep(1);
    }
	}

	const fetchPageEvaluationByCookie = async() => {
    if(cookieToken !== '') {
        let pageEvaluation = await authApiService.getPageEvaluationByCookieToken(cookieToken);
        pageEvaluation = pageEvaluation.filter(page_eva => page_eva.path === pathname);
        if(pageEvaluation.length > 0) {
            setStep(3);
        } else {
            setStep(1);
        }
    } else {
        setStep(1);
    }		
	}

	useEffect(() => {
        fetchPageEvaluationByIPAddress();
        fetchPageEvaluationByCookie();
    }, []);

    const handleRatingChange = (value) => {
        setFormData({...formData, rating: value, feedback_value: 0, feedback_details: ''});

		// Uncheck all radio inputs in fieldset-rating-one
		const radioInputs1 = document.querySelectorAll('.fieldset-rating-one input[type="radio"]');
		radioInputs1.forEach(input => { input.checked = false; });
	
		// Uncheck all radio inputs in fieldset-rating-two
		const radioInputs2 = document.querySelectorAll('.fieldset-rating-two input[type="radio"]');
		radioInputs2.forEach(input => { input.checked = false; });    

		setFeedbackChecked(false);
    }

    const handleFeedbackValueChange = (value) => {
        setFormData({...formData, feedback_value: value, feedback_details: ''}); 
		setFeedbackChecked(true);
    }

    const handleFeedbackDetailsChange = (value) => {
        setFormData({...formData, feedback_details: value});        
    }

    const handleSubmit = async() => {
	  	try {
			let result = await authApiService.createPageEvaluation(formData);			
		} catch(err) {
			console.log(err);
		}
    }

    return <>
        <Container className="rating-container pt-5 pb-5">
            <Row className="justify-content-center">
                <Col lg={variant == "boxed" ? "6" : "12"}>
                <div className="cmp-rating pt-lg-80 pb-lg-80" id="rating">
                  <div className="card shadow card-wrapper" data-element="feedback">
                    {step && step < 3 && <>
                    <div className="cmp-rating__card-first">
                      <div className="card-header border-0 ps-4">
                          <h2 className="rating-title mb-0" data-element="feedback-title">{label}</h2>
                      </div>
                      <div className="card-body pt-0">
                        <fieldset className="rating">
                          <legend className="d-none">Valuta da 1 a 5 stelle la pagina</legend>
                          <input disabled={step > 1 ? true : false} type="radio" id="star5a" name="ratingA" value="5" className="" data-focus-mouse="false" onClick={(e) => handleRatingChange(e.target.value)} />
                          <label className="full rating-star active" htmlFor="star5a" data-element="feedback-rate-5">
                            <svg className="icon icon-sm" role="img" aria-labelledby="first-star" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                            <span className="d-none" id="first-star">Valuta 5 stelle su 5</span>
                          </label>
                          <input disabled={step > 1 ? true : false} type="radio" id="star4a" name="ratingA" value="4" className="" data-focus-mouse="false" onClick={(e) => handleRatingChange(e.target.value)} />
                          <label className="full rating-star active" htmlFor="star4a" data-element="feedback-rate-4">
                            <svg className="icon icon-sm" role="img" aria-labelledby="second-star" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                            <span className="d-none" id="second-star">Valuta 4 stelle su 5</span>
                          </label>
                          <input disabled={step > 1 ? true : false} type="radio" id="star3a" name="ratingA" value="3" className="" data-focus-mouse="false" onClick={(e) => handleRatingChange(e.target.value)} />
                          <label className="full rating-star active" htmlFor="star3a" data-element="feedback-rate-3">
                            <svg className="icon icon-sm" role="img" aria-labelledby="third-star" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                            <span className="d-none" id="third-star">Valuta 3 stelle su 5</span>
                          </label>
                          <input disabled={step > 1 ? true : false} type="radio" id="star2a" name="ratingA" value="2" className="" data-focus-mouse="false" onClick={(e) => handleRatingChange(e.target.value)} />
                          <label className="full rating-star active" htmlFor="star2a" data-element="feedback-rate-2">
                            <svg className="icon icon-sm" role="img" aria-labelledby="fourth-star" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                            <span className="d-none" id="fourth-star">Valuta 2 stelle su 5</span>
                          </label>
                          <input disabled={step > 1 ? true : false} type="radio" id="star1a" name="ratingA" value="1" className="" data-focus-mouse="false" onClick={(e) => handleRatingChange(e.target.value)} />
                          <label className="full rating-star active" htmlFor="star1a" data-element="feedback-rate-1">
                            <svg className="icon icon-sm" role="img" aria-labelledby="fifth-star" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 1.7L9.5 9.2H1.6L8 13.9l-2.4 7.6 6.4-4.7 6.4 4.7-2.4-7.6 6.4-4.7h-7.9L12 1.7z"></path>
                              <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                            <span className="d-none" id="fifth-star">Valuta 1 stelle su 5</span>
                          </label>
                        </fieldset>
                      </div>
                    </div> 
                    <div className="form-rating mt-3 ps-4 pe-4">
                      <div className="active" data-step="1">
                        <div className="cmp-steps-rating">
                          
                          <fieldset className={((formData.rating > 3 && step === 1) ? "" : "d-none") + " fieldset-rating-one"} data-element="feedback-rating-positive">
                            <div className="iscrizioni-header w-100">
                              <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                <legend className="d-block d-lg-inline" data-element="feedback-rating-question">
                                  Quali sono stati gli aspetti che hai preferito?
                                </legend>
                                <span className="step">1/2</span>
                              </h3>
                            </div>
                            <div className="cmp-steps-rating__body">
                              <div className="cmp-radio-list">
                                <div className="card card-teaser shadow-rating">
                                  <div className="card-body">
                                    <div className="form-check m-0">
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating1" type="radio" id="radio-1" value="1" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-1" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Le indicazioni erano chiare</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating1" type="radio" id="radio-2" value="2" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-2" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Le indicazioni erano complete</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating1" type="radio" id="radio-3" value="3" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-3" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Capivo sempre che stavo procedendo correttamente</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating1" type="radio" id="radio-4" value="4" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-4" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Non ho avuto problemi tecnici</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating1" type="radio" id="radio-5" value="5" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-5" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Altro</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          
                          
                          <fieldset className={((formData.rating > 0 && formData.rating < 4 && step === 1) ? "" : "d-none") + " fieldset-rating-two"} data-element="feedback-rating-negative">
                            <div className="iscrizioni-header w-100">
                              <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow">
                                <legend className="d-block d-lg-inline" data-element="feedback-rating-question">
                                  Dove hai incontrato le maggiori difficoltà?
                                </legend>
                                <span className="step">1/2</span>
                              </h3>
                            </div>
                            <div className="cmp-steps-rating__body">
                              <div className="cmp-radio-list">
                                <div className="card card-teaser shadow-rating">
                                  <div className="card-body">
                                    <div className="form-check m-0">
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating2" type="radio" id="radio-6" className="" data-focus-mouse="false" value="6" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-6" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>A volte le indicazioni non erano chiare</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating2" type="radio" id="radio-7" value="7" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-7" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>A volte le indicazioni non erano complete</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating2" type="radio" id="radio-8" value="8" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-8" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>A volte non capivo se stavo procedendo correttamente</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating2" type="radio" id="radio-9" value="9" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-9" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Ho avuto problemi tecnici</label>
                                      </div>
                                      <div className="radio-body border-bottom border-light cmp-radio-list__item">
                                        <input name="rating2" type="radio" id="radio-10" value="10" onClick={(e) => handleFeedbackValueChange(e.target.value)} />
                                        <label htmlFor="radio-10" className="active" data-element="feedback-rating-answer" style={{zIndex: "999"}}>Altro</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          
                        </div>
                      </div>
                      
                      <div data-step="2" className={formData.rating > 0 && step === 2 ? "" : "d-none"}>
                        <div className="cmp-steps-rating">
                          <fieldset>
                            <div className="iscrizioni-header w-100">
                              <h3 className="step-title d-flex align-items-center justify-content-between drop-shadow mb-3">
                                <legend className="d-block d-lg-inline">
                                  Vuoi aggiungere altri dettagli?
                                </legend>
                                <span className="step">2/2</span>
                              </h3>
                            </div>
                            <div className="cmp-steps-rating__body">
                              <div className="form-group">
                              <label htmlFor="feedbackInputText" className="d-none">Dettaglio</label>
                                <input type="text" className="form-control" id="feedbackInputText" aria-describedby="feedbackInputTextDescription" maxLength="200" data-element="feedback-input-text" onChange={(e) => handleFeedbackDetailsChange(e.target.value)} />
                                <small id="feedbackInputTextDescription" className="form-text">
                                  Inserire massimo 200 caratteri</small>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                      
                      {formData.rating > 0 &&
                        <div className="d-flex flex-nowrap pt-4 w-100 justify-content-center button-shadow">                        
                          {step && step === 1 && <button className="btn btn-primary fw-bold btn-next" type="button" form="rating" data-focus-mouse="false" onClick={()=>setStep(step + 1)} disabled={!feedbackChecked}>Avanti</button>}
                          {step && step === 2 && <>
                              <button className="btn btn-outline-primary fw-bold me-4 btn-back" type="button" data-focus-mouse="false" onClick={()=>setStep(step - 1)}>Indietro</button>
                              <button className="btn btn-primary fw-bold btn-next" type="button" form="rating" data-focus-mouse="false" onClick={() => { handleSubmit(); setStep(step + 1);}}>Invia</button>
                          </>}                        
                        </div>
                      }
                    </div>
                    </>
                    }
                    {step && step === 3 &&
                      <div className="cmp-rating__card-second" data-step="3">
                        <div className="card-header border-0 mb-0">
                          <h2 className="rating-title mb-0" id="rating-feedback">Grazie, il tuo parere ci aiuterà a migliorare il
                            servizio!</h2>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                </Col>         
            </Row>
            
        </Container>
    </>;
}

export default WidgetPageRating;