import { OfficeClass } from 'pa_kit/apps/offices/models/office';
import { CategoryClass } from '../../categories/models/category';
import { TagClass } from 'pa_kit/apps/tags/models/tag';

export const EventContentSections =  {
    description: "Descrizione",
    location: "Luogo",
    timeline: "Data e orari",
    costs: "Costi",
    attachments: "Documenti",
    office: "Ufficio",
    more_info: "Ulteriori informazioni"
}

export class EventClass {    
  
    constructor(data){
        this.id = data.id;
        this.title = data.title;
        this.slug = data.slug;
        this.image = data.image;
        this.meta_description = data.meta_description;
        this.image_description = data.image_description;
        this.content = data.content;
        this.category = new CategoryClass(data.category_data);
        this.tags = data.tags_data?.map((tag) => new TagClass(tag)) || [];
        this.office = data.office_data ? new OfficeClass(data.office_data) : null;
        this.date_event = data.date_event;
        this.date_published = data.date_published;
        this.city = data.city;
        this.address = data.address;
        this.province = data.province;
        this.zip_code = data.zip_code;
        this.show_map = data.show_map;
        this.state = data.state;
        this.user = data.user;
        this.last_update = data.last_update;
    }
}

