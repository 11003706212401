// @ts-nocheck
import { Container } from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { TextClass } from '../models/text';
import ApiService from "../../../services/api";

const WidgetText = (props) => {

    const [ widget ] = useState(props.widget);

    const idText = widget.data.id_text;

    const [ text, setText ] = useState({});
    const [ isLoading, setIsLoading ] = useState(true);

    const fetchText = async() => {
        const textData = await ApiService.getText(idText);
        setText(new TextClass(textData));     
        setIsLoading(false);
    }

    useEffect(() => {
        fetchText();
    }, []);

    if(isLoading) return <div></div>;
    return <>
        <Container fluid className={widget.data.class + '-text page-text-content'}>
            {parse(text.description)}
        </Container>        
    </>;
}

export default WidgetText;