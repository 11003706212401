import { Col, Container, Icon, Row } from "design-react-kit";
import React from "react";

const Page404 = () => {

    return(
        <Container style={{padding: "80px 50px"}}>
            <Row className="text-center">
                <Col lg={{ offset: 3, size: 6 }} md={12}>
                    <Icon icon="it-close-circle" size="xl" />
                    <h2>Pagina non trovata!</h2>
                    <p>Ritorna alla Home del sito o digita, nel campo di ricerca, l'informazione che stavi cercando.</p>
                    <a href="/" className="btn btn-lg btn-primary mt-5">Vai alla home</a>
                </Col>
            </Row>
        </Container>
    );

}

export default Page404;