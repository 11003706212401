import { Container, Icon } from 'design-react-kit';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WidgetSearch = (props) => {

    const [ widget ] = useState(props.widget);

    const navigate = useNavigate();
    const [ searchKey, setSearchKey ] = useState("");

    const [isFocused, toggleFocus] = useState(false);

    const toggleFocusLabel = () => toggleFocus(true);
    const toggleBlurLabel = (e) => {
        if (e.target.value === '') {
            toggleFocus(!isFocused);
        }
    };

    const toSearch = () => {
        navigate('/ricerca/',{state: { search_in: widget.data.search_in, id: widget.data.search_id, key: searchKey } });
    }

    return <>
        <Container fluid className={widget.data.class + '-search page-search-content mt-5'}>
            <div className='form-group'>
                <div className='input-group'>
                    <label htmlFor={'input-group' + widget.data.class} className={isFocused ? 'active' : ''}>{widget.data.label}</label>
                    <input type='text' className={isFocused ? 'form-control focus--mouse' : 'form-control'} onFocus={toggleFocusLabel} onBlur={toggleBlurLabel} id={'input-group' + widget.data.class} name={'input-' + widget.data.class} onChange={(e) => setSearchKey(e.target.value)} />
                    <div className='input-group-append'>
                        <button onClick={() => {toSearch()}} className='btn btn-primary' type='button' id='button-3'><Icon icon='it-search' aria-hidden size='sm' color="white" /></button>
                    </div>
                </div>
            </div>
        </Container>        
    </>;
}

export default WidgetSearch;