import { useContext, useEffect, useState } from "react";
// import { ModuleStep, ModuleStepStatus } from "./module_step";
import ApiService from '../../../services/api'
import { Service } from "../../services/models/service";
import { ActaAuthContext } from "pa_kit/auth";

export class Module {

    constructor(data){
        console.log(data.fieldset);

        this.id = data.id;
        this.name = data.name ;
        this.description = data.description ;
        this.service = data.service ? new Service(data.service) : null;
        // this.steps = data.fieldset?.map((data:any) => new ModuleStep(data)) ?? [];
    }
}
