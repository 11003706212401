import React from "react";
import { Pager, PagerItem, PagerLink, Icon } from 'design-react-kit';


const Pagination = (props) => {
    const pageNumbers = [];
    const totalElements = props.totalElements;
    const elementsPerPage = props.elementsPerPage;
    const currentPage = props.currentPage;
    const onPageChange = props.onPageChange;
	const type = props.type;
  
    for (let i = 1; i <= Math.ceil(totalElements / elementsPerPage); i++) {
      	pageNumbers.push(i);
    }
      
    let startIndex = Math.max(0, currentPage - 2);
    let endIndex = Math.min(startIndex + 2, pageNumbers.length - 1);
    startIndex = Math.max(0, endIndex - 2);
  
    return <>
      	<center>
        	<Pager className='mb-3 mt-5' aria-label='Paginazione' style={{ justifyContent: 'center' }}>
          		<ul className="pagination">
				{currentPage > 1 && (
					<PagerItem key={'page_previous'}>
						<PagerLink previous href={"#" + type + "-elements-wrapper"} onClick={() => onPageChange(currentPage - 1)}>
							<Icon icon='it-chevron-left' aria-hidden />
						</PagerLink>
					</PagerItem>
				)}
				{pageNumbers.length > 3 && currentPage > 2 && <>
					<PagerItem key={'page_first'}>
						<PagerLink previous href={"#" + type + "-elements-wrapper"} onClick={() => onPageChange(1)}>
							1
						</PagerLink>
					</PagerItem>
					{currentPage > 3 && <div style={{padding: '10px'}}>...</div>}					
				</>
				}
				{pageNumbers.slice(startIndex, endIndex + 1).map((number) => <React.Fragment key={'page_'+ number}>
					<PagerItem className='d-none d-sm-block'>
						<PagerLink
							href={"#" + type + "-elements-wrapper"}
							className={number === currentPage ? 'page-link active' : 'page-link'}
							onClick={() => {
								onPageChange(number);
								window.scrollTo(0, 0);
							}}
						>
							{number}
						</PagerLink>
					</PagerItem>
				</React.Fragment>)}
				{currentPage < pageNumbers.length && currentPage < pageNumbers.length - 1 && pageNumbers.length > 3 && <>
					{currentPage < pageNumbers.length -2 && <div style={{padding: '10px'}}>...</div>}
					<PagerItem key={'page_last'}>
						<PagerLink next href={"#" + type + "-elements-wrapper"} onClick={() => onPageChange(pageNumbers.length)}>
							{pageNumbers.length}
						</PagerLink>
					</PagerItem>
				</>
				}				
				{currentPage < pageNumbers.length && <>
					<PagerItem key={'page_next'}>
						<PagerLink next href={"#" + type + "-elements-wrapper"} onClick={() => onPageChange(currentPage + 1)}>
							<Icon icon='it-chevron-right' aria-hidden />
						</PagerLink>
					</PagerItem>
				</>}
          		</ul>
				<div style={{padding: '10px', color: '#5b6f82'}}><strong>{currentPage} di {pageNumbers.length}</strong></div>
        	</Pager>
      	</center>
    </>;
};

export default Pagination;