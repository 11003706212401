// @ts-nocheck
import { Row, Col, Container, Navbar, NavItem, LinkList, NavLink, Table, Icon } from 'design-react-kit';
import React, { useState, useRef } from 'react';
import ApiService from '../../../services/api';
import { useEffect } from 'react';
import env from '../../../env';


const WidgetStats = (props) => {

    const dateOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    };
      
    const today = new Date().toLocaleDateString('it-IT', dateOptions);

    const [ weeklyStatistics, setWeeklyStatistics ] = useState();
    const [ monthlyStatistics, setMonthlyStatistics ] = useState();
    const [ yearlyStatistics, setYearlyStatistics ] = useState();
    const [ weeklyPageStatistics, setWeeklyPageStatistics ] = useState();

    const [ isLoading, setIsLoading ] = useState(true);

    const hiddenRowsRef = useRef([]);

    const toggleRowVisibility = (index) => {
        const hiddenRow = hiddenRowsRef.current[index];
        if (hiddenRow) {
            hiddenRow.classList.toggle('hidden');
        }
    };

    const toggleSubRowVisibility = (subIndex) => {
        const elements = document.querySelectorAll(`.innerSubTable${subIndex}`);

        elements.forEach((element) => {
          element.classList.toggle('hidden');
        });
    };


    const fetchWeeklyStatistics = async() => {
        
        const requestWeekly = "method=Actions.get&idSite=" + env.ANALYTICS_SITE_ID + "&period=week&date=lastWeek";
        const statisticsData = await ApiService.getStats(requestWeekly);
        setWeeklyStatistics(statisticsData);
    }

    const fetchWeeklyPageStatistics = async() => {

        const requestWeeklyPageStatistics = "method=Actions.getPageUrls&expanded=1&filter_limit=11&idSite=" + env.ANALYTICS_SITE_ID + "&period=week&date=lastWeek";
        const statisticsData = await ApiService.getStats(requestWeeklyPageStatistics);
        
        setWeeklyPageStatistics(statisticsData);
    }

    const fetchMonthlyStatistics = async() => {

        const currentMonth = new Date().getMonth();
        let monthlyStatsArray = [];
        
        const requestMontly = "method=Actions.get&idSite=" + env.ANALYTICS_SITE_ID + "&period=month&date=last" + (currentMonth+1);        
        const statisticsData = await ApiService.getStats(requestMontly);
        Object.entries(statisticsData).forEach(([yearMonth, stats]) => {
            const dateString = yearMonth;
            const dateParts = dateString.split("-");
            const year = parseInt(dateParts[0]);
            const month = parseInt(dateParts[1]) - 1;

            const date = new Date(year, month);
            const monthName = date.toLocaleString("it-IT", { month: "long" });

            monthlyStatsArray.push({month: monthName, total_visits: stats.nb_pageviews, unique_visits: stats.nb_uniq_pageviews});
            
        });       

        setMonthlyStatistics(monthlyStatsArray);
    }

    const fetchYearlyStatistics = async() => {

        const startingYear = env.ANALYTICS_STARTING_YEAR;
        const currentYear = new Date().getFullYear();
        let yearlyStatsArray = [];

        const requestYearly = "method=Actions.get&idSite=" + env.ANALYTICS_SITE_ID + "&period=year&date=last" + ((currentYear - startingYear) + 1); 
        const statisticsData = await ApiService.getStats(requestYearly);
        Object.entries(statisticsData).forEach(([year, stats]) => {

            yearlyStatsArray.push({year: year, total_visits: stats.nb_pageviews, unique_visits: stats.nb_uniq_pageviews});
            
        });

        setYearlyStatistics(yearlyStatsArray);
        setIsLoading(false);
    }

    useEffect(() => {
        if(env.ANALYTICS_SITE_ID !== '') {
            fetchWeeklyStatistics();
            fetchWeeklyPageStatistics();
            fetchMonthlyStatistics();
            fetchYearlyStatistics();
        } else {
            setIsLoading(false);
        }
    }, []);

    if(isLoading || env.ANALYTICS_SITE_ID === '') return <></>;
    return <>
        <Container className="mb-5">
            <Row className="stats-content-wrapper">
                <Col lg={4} className="stats-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                    <NavItem key={"key_sectionlink_weekly_stats"} >
                                        <NavLink href="#weekly-stats" className="sidebar-nav-link">Statistiche settimanali</NavLink>
                                    </NavItem>
                                    <NavItem key={"key_sectionlink_monthly_stats"} >
                                        <NavLink href="#monthly-stats" className="sidebar-nav-link">Statistiche mensili</NavLink>
                                    </NavItem>
                                    <NavItem key={"key_sectionlink_yearly_stats"} >
                                        <NavLink href="#yearly-stats" className="sidebar-nav-link">Statistiche annuali</NavLink>
                                    </NavItem>
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="stats-section-content">
                    
                    <div className="mb-4">
                        <h4 className="stats-content-title" id="weekly-stats">Statistiche settimanali</h4>                        
                        <div className="stats-content-description">
                            <p>Statistiche di accesso al sito web degli ultimi 7 giorni.</p>                            
                            <Table striped className="mt-4" style={{fontSize: '14px'}}>
                                <tbody>
                                    <tr>
                                        <th scope='row'>Visite Totali</th>
                                        <td>{weeklyStatistics && weeklyStatistics.nb_pageviews}</td>
                                    </tr>
                                    <tr>
                                        <th scope='col'>Visite Uniche</th>
                                        <td>{weeklyStatistics && weeklyStatistics.nb_uniq_pageviews}</td>
                                    </tr>
                                </tbody>
                            </Table>    
                            <h5 className="mt-5">Le pagine più visitate della settimana</h5> 
                            <Table className="mt-4" style={{fontSize: '14px'}}>
                                <thead>
                                    <tr>
                                        <th>URL</th>
                                        <th>Visite Totali</th>
                                        <th>Visite Uniche</th>
                                    </tr>
                                </thead>
                                {weeklyPageStatistics && weeklyPageStatistics.map((weeklyPageStats, index) => weeklyPageStats.label !== '/index' && weeklyPageStats.label !== 'backend' && weeklyPageStats.label !== 'admin' &&
                                    <React.Fragment key={index}>
                                        <tbody>
                                        <tr>
                                            <td className="showSubTable" onClick={() => toggleRowVisibility(index)}>
                                                {weeklyPageStats.subtable && weeklyPageStats.subtable.length > 1 && <Icon icon="it-plus-circle" size="xs" style={{cursor: 'pointer'}} /> } <a href={env.FRONTEND_BASE_URL + weeklyPageStats.label.replace(/^\//, '')} title={weeklyPageStats.label}>{weeklyPageStats.label}</a>
                                            </td>
                                            <td>{weeklyPageStats.nb_hits}</td>
                                            <td>{weeklyPageStats.nb_visits}</td>
                                        </tr>
                                        </tbody>
                                        {weeklyPageStats.subtable && weeklyPageStats.subtable.length > 0 &&
                                            <tbody className="hidden" ref={(element) => {hiddenRowsRef.current[index] = element;}}>
                                                {weeklyPageStats.subtable && weeklyPageStats.subtable.map((subTableStats, subIndex) => subTableStats.label !== '/index' && subTableStats.label !== 'backend' && subTableStats.label !== 'admin' &&
                                                    <React.Fragment key={subIndex}>
                                                        <tr>
                                                            <td style={{paddingLeft: '40px'}} onClick={() => toggleSubRowVisibility(subIndex)}>
                                                                {subTableStats.subtable && subTableStats.subtable.length > 1 && <Icon icon="it-plus-circle" size="xs" style={{cursor: 'pointer'}} /> }
                                                                <a href={env.FRONTEND_BASE_URL + weeklyPageStats.label + '/' + subTableStats.label.replace(/^\//, '')} title={subTableStats.label}>{subTableStats.label}</a>
                                                            </td>
                                                            <td>{subTableStats.nb_hits}</td>
                                                            <td>{subTableStats.nb_visits}</td>
                                                        </tr>
                                                        {subTableStats.subtable && subTableStats.subtable.length > 0 && subTableStats.subtable.map((innerSubTable, innerSubIndex) => innerSubTable.label !== '/index' && innerSubTable.label !== 'backend' && innerSubTable.label !== 'admin' &&
                                                            <tr className={'hidden innerSubTable' + subIndex}>
                                                                <td style={{paddingLeft: '80px'}}><a href={env.FRONTEND_BASE_URL + weeklyPageStats.label + '/' + subTableStats.label.replace(/^\//, '') + '/' + innerSubTable.label.replace(/^\//, '')} title={innerSubTable.label}>{innerSubTable.label}</a></td>
                                                                <td>{innerSubTable.nb_hits}</td>
                                                                <td>{innerSubTable.nb_visits}</td>
                                                            </tr>
                                                        )}                                        
                                                    </React.Fragment> 
                                                )}
                                            </tbody>
                                        }
                                    </React.Fragment>
                                )}
                            </Table>                                   
                        </div>

                        <h4 className="stats-content-title mt-5" id="monthly-stats">Statistiche mensili</h4>                        
                        <div className="stats-content-description">
                            <p>Statistiche di accesso mensili.</p>
                            <Table striped className="mt-4" style={{fontSize: '14px'}}>
                                <thead>
                                    <tr>
                                        <th>Mese</th>
                                        <th>Visite Totali</th>
                                        <th>Visite Uniche</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {monthlyStatistics && monthlyStatistics.slice(0).reverse().map((monthlyStats, index) => <React.Fragment key={"key_month_" + index}>
                                    <tr>
                                        <td>{monthlyStats.month.charAt(0).toUpperCase() + monthlyStats.month.slice(1)}</td>
                                        <td>{monthlyStats.total_visits}</td>             
                                        <td>{monthlyStats.unique_visits}</td> 
                                    </tr>     
                                </React.Fragment>)}
                                </tbody>
                            </Table>
                        </div>

                        <h4 className="stats-content-title mt-5" id="yearly-stats">Statistiche annuali</h4>                        
                        <div className="stats-content-description">
                            <p>Statistiche di accesso annuali.</p>
                            <Table striped className="mt-4" style={{fontSize: '14px'}}>
                                <thead>
                                    <tr>
                                        <th>Anno</th>
                                        <th>Visite Totali</th>
                                        <th>Visite Uniche</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {yearlyStatistics && yearlyStatistics.slice(0).reverse().map((yearlyStats, index) => <React.Fragment key={"key_year_" + index}>
                                    <tr>
                                        <td>{yearlyStats.year}</td>
                                        <td>{yearlyStats.total_visits}</td>             
                                        <td>{yearlyStats.unique_visits}</td> 
                                    </tr>     
                                </React.Fragment>)}
                                </tbody>
                            </Table>
                        </div>
                    </div>                    

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{today}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    </>;
}

export default WidgetStats;