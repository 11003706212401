/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Navbar, LinkList, NavItem, NavLink, Icon, AvatarWrapper, AvatarExtraText, AvatarIcon, Spinner, Card, CardBody, CardTitle, CardReadMore, CardText } from "design-react-kit";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import { StructureClass, StructureContentSections } from "../models/structure";
import authApiService from "pa_kit/services/api";
import { ActaAuthContext } from "pa_kit/auth";
import env from '../../../../env';


const WidgetStructure = (props) => {
    const auth = useContext(ActaAuthContext);
    const auth_config = auth.organization.active_licenses?.services?.auth;

    const [ widget ] = useState(props.widget);

    const [ structure, setStructure ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let id_structure;

    if(widget.data.id_structure) {
        id_structure = widget.data.id_structure;
    }

    const fetchStructure = async() => {
        if(id_structure !== undefined) {
            const structureData = await authApiService.getStructure(id_structure);
            setStructure(new StructureClass(structureData));
        } else {
            const structureData = await authApiService.getStructures();
            setStructure(structureData);
        }
        setIsLoading(false);
    }    

    useEffect(() => {
        fetchStructure();
    }, []);

    if(id_structure === undefined) return <>
        <h2 className="structure-page-title mt-5">{widget.data.label ? widget.data.label : 'Elenco Organi'}</h2>
        <Container fluid className={widget.data.class + '-structure page-structure-content'}>
            <Row>
            {structure && structure.map((structure) => 
                <Col xs='12' lg='4' className="mt-4" key={"key_structureItem_" + structure.id}>                    
                    <Card className="structure-card border border-light shadow-sm">
                        <CardBody>
                            <CardTitle tag='h3' className='big-heading'>
                                <a href={'/' + structure.slug + '/'} className="text-decoration-none me-2" title={structure.name}>{structure.name}</a>
                            </CardTitle>
                            <CardText>
                                {parse(structure.content.role.description)}
                            </CardText>
                            <CardReadMore href={'/' + structure.slug + '/'} text='Vai alla pagina' iconName='it-arrow-right' />
                        </CardBody>
                    </Card>
                </Col>
            )}
            </Row>
        </Container>
    </>

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;
    return (
        <Container className="mb-5">
            <Row className="structure-content-wrapper">
                <Col lg={4} className="structure-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(StructureContentSections).map((key, index) => ((structure?.content[key] && (Array.isArray(structure?.content[key]) ? (structure?.content[key].length > 0) : (structure?.content[key].description !== '' && structure?.content[key].description !== null))) || (key === 'staff' && structure?.staff.length > 0)) &&
                                    <NavItem key={"key_sectionlink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(StructureContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="structure-section-content">
                    {Object.keys(StructureContentSections).map((key, index) => (structure?.content[key] || key === 'staff') &&
                        <div key={"key_structureSection_" + index} className="mb-4">
                            {key !== 'staff' && structure?.content[key].description &&  <>
                                <h4 className="structure-content-title" id={key}>{ Object.values(StructureContentSections)[index] }</h4>                           
                                <div className="structure-content-description">
                                    { parse(structure.content[key].description) }
                                </div>
                            </>}

                            {key === 'staff' && structure?.staff.length > 0 && <>
                                <h4 className="structure-content-title" id={key}>{ Object.values(StructureContentSections)[index] }</h4>
                                <Row> 
                                    {structure?.staff.map((staff) =>
                                    <Col lg="6" key={"key_staffSection_" + staff.id} className="staff-card-col">
                                        <AvatarWrapper className="staff-card" extra='text'>
                                            <AvatarIcon size='xl'>
                                                <img src={staff.image ? auth_config?.backend_url + staff.image : auth_config?.backend_url + 'media/organization/default_avatar.jpg'} alt={staff.first_name + ' ' + staff.last_name} />
                                            </AvatarIcon>
                                            <AvatarExtraText>
                                                <h4> <a href={'/' + staff.slug}>{staff.first_name + ' ' + staff.last_name}</a> </h4>
                                                <p>{staff.role}</p>
                                            </AvatarExtraText>
                                        </AvatarWrapper> 
                                    </Col>
                                    )} 
                                </Row>
                                </>                          
                            }

                            {key === 'documents' && structure.content[key].length > 0 && <>
                                <h4 className="structure-content-title" id={key}>{ Object.values(StructureContentSections)[index] }</h4>   
                                {structure.content[key].map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                <div className="mt-3">{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={auth_config?.backend_url + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                                )}
                                </>
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(structure.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    )
}

export default WidgetStructure;