/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { Container, Row, Col, AvatarWrapper, AvatarIcon, AvatarExtraText, Navbar, LinkList, NavItem, NavLink, Icon, Spinner, CardTitle, CardText, CardBody, Card } from 'design-react-kit';
import React, { useState, useEffect, useContext } from 'react';
import authApiService from 'pa_kit/services/api';
import { StaffClass, StaffContentSections } from "../models/staff";
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/it';
import { ActaAuthContext } from "pa_kit/auth";
import env from '../../../../env';

const StructuresList = (props) => {

    const idStaff = props.idStaff;

    const [ structures, setStructures ] = useState();    
    const [ sectors, setSectors ] = useState();
    const [ offices, setOffices ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const fetchStructures = async() => {
        const structuresData = await authApiService.getStructures();
        const structures = structuresData.filter(structure => structure.staff.some(person => person.id === idStaff));
        setStructures(structures);
    }

    const fetchSectors = async() => {
        const sectorsData = await authApiService.getSectors();
        const sectors = sectorsData.filter(sector => sector.staff.some(person => person.id === idStaff));
        setSectors(sectors);
    }

    const fetchOffices = async() => {
        const officesData = await authApiService.getOffices();
        const offices = officesData.filter(office => office.staff.some(person => person.id === idStaff));
        setOffices(offices);
    }

    useEffect(() => {
        fetchStructures();
        fetchSectors();
        fetchOffices();
        setIsLoading(false);
    }, []);

    if(!offices && !structures && !sectors && isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;
    return <>
        {(structures?.length !== 0 || sectors?.length !== 0 || offices?.length !== 0) && <h5 className="mb-4 mt-4"><strong>Fa parte di</strong></h5>}
        <Row className="mb-2">
        {structures && structures.map((structure) =>                                         
            <Col lg="6" key={"key_structureItem_" + structure.id}>
                <Card className="structure-card-box">
                    <CardBody className="structure-card">
                        <Row>
                            <Col lg="8" xs="8">
                                <CardTitle tag='h5' className="mb-1">
                                    <a href={'/' + structure.slug + '/'} title={structure.name}>{structure.name}</a>
                                </CardTitle>
                                <CardText>
                                    Organi di governo
                                </CardText>
                            </Col>
                            <Col lg={{size: 3, offset: 1}} xs="4">
                                <Icon icon="it-pa" size="lg" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>                                                                              
        )}
        </Row>
        <Row className="mb-2">
        {sectors && sectors.map((sector) => 
            <Col lg="6" key={"key_sectorItem_" + sector.id}>
                <Card className="structure-card-box">
                    <CardBody className="structure-card">
                        <Row>
                            <Col lg="8" xs="8">
                                <CardTitle tag='h5' className="mb-1">
                                    <a href={'/' + sector.slug + '/'} title={sector.name}>{sector.name}</a>
                                </CardTitle>
                                <CardText>
                                    Settore
                                </CardText>
                            </Col>
                            <Col lg={{size: 3, offset: 1}} xs="4">
                                <Icon icon="it-pa" size="lg" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>                                         
        )}
        </Row>
        <Row className="mb-2">
        {offices && offices.map((office) =>                                         
            <Col lg="6" key={"key_officeItem_" + office.id}>
                <Card className="structure-card-box">
                    <CardBody className="structure-card">
                        <Row>
                            <Col lg="8" xs="8">
                                <CardTitle tag='h5' className="mb-1">
                                    <a href={'/' + office.slug + '/'} title={office.name}>{office.name}</a>
                                </CardTitle>
                                <CardText>
                                    Ufficio
                                </CardText>
                            </Col>
                            <Col lg={{size: 3, offset: 1}} xs="4">
                                <Icon icon="it-pa" size="lg" />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>                                    
        )}
        </Row>
    </>
}

const WidgetStaff = (props) => {
    const auth = useContext(ActaAuthContext);
    const auth_config = auth.organization.active_licenses?.services?.auth;

    const [ widget ] = useState(props.widget);   

    const [ staff, setStaff ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    let idStaff;
    let staffType;
    let menuLabel;

    if(widget.data.id_staff) {
        idStaff = widget.data.id_staff;
    }

    if(widget.data.staff_type) {
        staffType = widget.data.staff_type;
    }

    if(widget.data.label !== undefined && widget.data.label !== null){
        menuLabel = widget.data.label;
    } else {
        if(staffType === 'politician') {
            menuLabel = 'Elenco dei politici';
        } else {
            menuLabel = 'Elenco personale amministrativo';
        }
    }

    const fetchStaff = async() => {
        if(idStaff === undefined) {
            const staffListData = await authApiService.getStaff();
            const staffList = staffListData.filter(staff => staff.type === staffType);
            setStaff(staffList);
        } else {
            const staffData = await authApiService.getStaffProfile(idStaff);
            setStaff(new StaffClass(staffData));
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchStaff();
    }, [isLoading]);

    if(idStaff === undefined)  return <>
        <Container fluid className={widget.data.class + '-staff page-staff-content mt-5'}>
            <h2 className="staff-page-title">{menuLabel}</h2>            
            <Row className="mt-5"> 
            {staff && staff.map((staff) =>          
                <Col lg="4" key={"key_staffSection_" + staff.id} className="staff-card-col">
                    <AvatarWrapper className="staff-card" extra='text'>
                        <AvatarIcon size='xl'>
                            <img src={staff.image ? auth_config?.backend_url + staff.image : auth_config?.backend_url + 'media/organization/default_avatar.jpg'} alt={staff.first_name + ' ' + staff.last_name} />
                        </AvatarIcon>
                        <AvatarExtraText>
                            <h4> <a href={'/' + staff.slug + '/'}>{staff.first_name + ' ' + staff.last_name}</a> </h4>
                            <p>{staff.role}</p>
                        </AvatarExtraText>
                    </AvatarWrapper> 
                </Col>
            )}
            </Row>            
        </Container>        
    </>;

    if(isLoading) return <Container style={{ padding: "50px" }}><Spinner active /></Container>;
    return (
        <Container className="mb-5">
            <Row><h3 className="staff-role-title">{staff.role}</h3></Row>
            <Row className="staff-profile-content-wrapper">
                <Col lg={4} className="staff-profile-section-sidebar">
                    <div className='sticky-top navbar-wrapper'>
                        <Navbar expand='lg' className='it-navscroll-wrapper it-top-navscroll'>
                            <div className='menu-wrapper w-100'>
                                <LinkList wrapperClassName='menu-link-list' header={<h3>Indice della pagina</h3>}>
                                {Object.keys(StaffContentSections).map((key, index) => (staff?.content[key] || key === 'staff') && (Array.isArray(staff?.content[key]) ? (staff?.content[key].length > 0) : (staff?.content[key].description !== '' && staff?.content[key].description !== null)) &&
                                    <NavItem key={"key_sectionlink_" + key} >
                                        <NavLink href={"#" + key} className="sidebar-nav-link">{Object.values(StaffContentSections)[index]}</NavLink>
                                    </NavItem>
                                )}
                                </LinkList>
                            </div>
                        </Navbar>
                    </div>
                </Col>
                <Col lg={8} className="staff-profile-section-content">
                    {Object.keys(StaffContentSections).map((key, index) => staff?.content[key] &&
                        <div key={"key_staffSection_" + index} className="mb-4">
                            {key === 'role' && staff?.content[key].description &&  <>     
                                <h4 className="staff-profile-content-title" id={key}>{ Object.values(StaffContentSections)[index] }</h4>                       
                                <div className="staff-profile-content-description">
                                    { parse(staff.content[key].description) }
                                    {staff.image && <img className="staff-profile-img" src={auth_config?.backend_url + staff.image} alt={staff.first_name + staff.last_name} title={staff.first_name + staff.last_name} />}
                                    <div className="mt-4">{parse(staff.content['description'].description)}</div>
                                    <StructuresList idStaff={staff.id} />
                                </div>
                            </>}                             
                            
                            {key !== 'role' && staff?.content[key].description && <>   
                                <h4 className="staff-profile-content-title" id={key}>{ Object.values(StaffContentSections)[index] }</h4>                         
                                <div className="staff-profile-content-description">
                                    { parse(staff.content[key].description) }
                                </div>
                            </>}                                                       
                            
                            {key === 'documents' && staff.content[key].length > 0 && <>
                                <h4 className="staff-profile-content-title" id={key}>{ Object.values(StaffContentSections)[index] }</h4>
                                {staff.content[key].map((document, i) =>
                                <React.Fragment key={"key_documentSection_" + i}>
                                <div className="mt-3">{ parse(document.description) }</div>
                                    <Row>
                                    {document.attachments.map((attachment) => 
                                        <Col key={"key_attachmentlink_" + attachment.name} lg={6} className="mb-2">                                            
                                            <div className="attachment-box">
                                                <a href={auth_config?.backend_url + encodeURIComponent(attachment.url)} className="text-decoration-none" title={attachment.name} target="_blank" rel="noreferrer"><Icon icon="it-clip" /> {attachment.name}</a>
                                            </div>
                                        </Col>
                                    )}
                                    </Row>
                                </React.Fragment>
                                )}
                                </>
                            }
                        </div>

                    )}

                    <div className="mt-5">
                        <h5>Ultimo aggiornamento</h5>
                        <p>{moment(staff.last_update).format('D MMMM Y')}</p>
                    </div>
                        
                </Col>
            </Row>
        </Container>
    )
}

export default WidgetStaff;