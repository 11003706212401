// @ts-nocheck
import { useContext } from "react";
import { ActaAuthContext } from "pa_kit/auth";

import { Row, Col, Container, Card, CardBody, Icon } from 'design-react-kit';
import env from '../../../../env';

const WidgetContacts = (props) => {	

    const auth = useContext(ActaAuthContext);
    const actagov_config = auth.organization.active_licenses?.services?.actagov;
    const assistenza_config = auth.organization.active_licenses?.services?.assistenza;
    const contact_links = auth.websiteSettings.contact_links?.links;

    return <>
        <Container className="rating-container pt-5 pb-5">
            <Row className="justify-content-center">
                <Col lg="6">
                    <Card spacing className='card-bg card-contacts'>
                        <CardBody>
                            <h2 className="contacts-title">Contatta il comune</h2>
                            <ul className="contacts-list mt-3">
                                <li className="mb-3">
                                    <a href={(contact_links && contact_links.faq) ? contact_links.faq.link : '#'}><Icon icon="it-help-circle" size="sm" className="contacts-icon" /><span className="ms-2">Leggi le domande frequenti</span></a>
                                </li>
                                <li className="mb-3">
                                    <a href={(contact_links && contact_links.help) ? contact_links.help.link : '#'} data-element="contacts"><Icon icon="it-mail" size="sm" className="contacts-icon" /><span className="ms-2">Richiedi assistenza</span></a>
                                </li>
                                <li className="mb-3">
                                    <a href={(contact_links && contact_links.appointment) ? contact_links.appointment.link : '#'} data-element="appointment-booking"><Icon icon="it-calendar" size="sm" className="contacts-icon" /><span className="ms-2">Prenota appuntamento</span></a>
                                </li>
                            </ul>
                            <h2 className="contacts-title mt-2">Problemi in città</h2>
                            <ul className="contacts-list mt-3">
                                <li className="mb-3">
                                    <a href={(contact_links && contact_links.report) ? contact_links.report.link : '#'}><Icon icon="it-map-marker-circle" size="sm" className="contacts-icon" /><span className="ms-2">Segnala disservizio</span></a>
                                </li>
                            </ul>
                        </CardBody>
                    </Card>
                </Col>         
            </Row>
            
        </Container>
    </>;
}

export default WidgetContacts;